import React from "react";
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';


const Nav = props => {
    let menu = <></>;

    let homePath = '/helm';
    let currentLocation = useLocation();

    if (props.tenant !== null){
        let role = props.user.caps.role;

        let tid = encodeURIComponent(props.tenant.id);
        homePath = `/helm/t/${tid}`;
        let tenantItems = [
            [homePath + '/edit', 'Edit Tenant', ['admin', 'basic'].includes(role)],
            [homePath + '/corpora', 'Corpora', ['admin', 'basic'].includes(role)],
            [homePath + '/model-defs', 'Models', ['admin', 'basic', 'consumer'].includes(role)],
            [homePath + '/analysis-outputs', 'Analyses', ['admin', 'basic'].includes(role)],
            [homePath + '/docs', 'Documents', ['admin', 'basic'].includes(role)]
        ].filter(rt => rt[2])
        .map(rt => {
            let cn = currentLocation.pathname === rt[0] ? 'menu-item active' : 'menu-item';

            return <li className={cn} key={rt[0]}><Link to={rt[0]}>{rt[1]}</Link></li>;
        });

        let adminSection = null;
        if (role === 'admin'){
            adminSection = (<li className='section'>
                    <div className='section-title'>Admin</div>
                    <ul>
                        <li className='menu-item'><Link to={`${homePath}/admin/log-details`}>Logs</Link></li>
                    </ul>
                </li>);
        }

        menu = (
            <ul className='menu'>
                <li className='section'>
                    <div className='section-title'>{props.tenant.name}</div>
                    <ul>
                        {tenantItems}
                    </ul>
                </li>
                {adminSection}
            </ul>
        );
    }

    let logoFileRelPath = '/rectangle_withtag_transp.png',
        logoAlt = 'Apt Crowd, LLC.';
    if (props.tenant && props.tenant.name.toLowerCase().indexOf('vendux') > -1) {
        logoFileRelPath = '/Vendux_Logo_Main.png';
        logoAlt = 'Vendux, LLC.';
    }

    return (
        <div className='layout-nav'>
            <div className='branding'><Link to={homePath}><img src={process.env.PUBLIC_URL + logoFileRelPath} alt={logoAlt} /></Link></div>
            {menu}
        </div>
    );
};

function mapStateToProps(state){
    return {
        tenant: state.tenant.selectedTenant,
        user: state.user
    };
}

export default connect(mapStateToProps)(Nav);