import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ContentScroller from "../../shared/components/ContentScroller";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {ajaxWatched, apiRequest} from "../../shared/services";
import {ButtonGroup, DropdownButton, Spinner} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import DropdownItem from "react-bootstrap/DropdownItem";
import { Redirect } from "react-router-dom";

export function findCorpora(tenantId, callback) {
    let corpusCount = undefined;
    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, '/corpora')
                .then(response => {
                    corpusCount = parseInt(response.headers.get('Total-Count'))
                    return response.json()
                })
                .then(json => callback(json.result, corpusCount))
        ));
    }
}

const CorporaHeader = props => {
    if (props.corpusCount !== undefined) {
        let noun = props.corpusCount === 1 ? 'Corpus' : 'Corpora';
        return (
            <>
                <h3>{props.corpusCount} {noun}</h3>
                <Button onClick={props.onAddNewCorpus}>Add New Corpus</Button>
            </>
        );
    }
}

const CorpusSummary = props => {
    let c = props.corpus;
    let selectorCnt = c.selectors.length;
    let docTypeCnt = c.referencedDocTypes.length;
    let docTypes = new Set(c.referencedDocTypes);
    for (var i=0; i < c.selectors.length; i++){
        docTypes.add(c.selectors[i].docType);
    }
    let docTypeSummary = Array.from(docTypes).sort().join(', ');

    return (
        <Col className="my-2">
            <Card style={{height: '100%'}}>
            <Card.Header as="h5">
                {c.name}
                <ButtonGroup className="float-right">
                    <Button onClick={() => props.onEdit(c)} >Edit</Button>
                    <DropdownButton title="" as={ButtonGroup}>
                        <DropdownItem eventKey={1}>Task 1</DropdownItem>
                        <DropdownItem eventKey={2}>Task 2</DropdownItem>
                    </DropdownButton>
                </ButtonGroup>
            </Card.Header>
            <Card.Body>
                <Container>
                    <Row>
                        <Col>
                            <p>{c.description}</p>
                            <p>{c.notes}</p>
                        </Col>
                        <Col>
                            <p><em>{selectorCnt}</em> Document Selector(s)</p>
                            <p><em>{docTypeCnt}</em> Referenced Doc Types</p>
                            <p>{docTypeSummary}</p>
                        </Col>
                    </Row>
                </Container>

            </Card.Body>
        </Card>
        </Col>
    )
}

const ListCorpora = props => {
    const [corpora, setCorpora] = useState(undefined);
    const [corpusCount, setCorpusCount] = useState(undefined);
    const [redirect, setRedirect] = useState(undefined);

    useEffect(() => {
        if (corpora === undefined) {
            props.dispatch(findCorpora(props.tenant.id, (result, cnt) =>
            {
                setCorpusCount(cnt);
                setCorpora(result);
            }));
        }
    }, [corpora, props])

    if (redirect !== undefined)
    {
        return <Redirect to={redirect} />
    }


    const addNew = function() {
        setRedirect('./corpus/');
    }

    const redirectToEdit = function(corpus) {
        setRedirect(`./corpus/${corpus.id}`);
    }

    let items = '';

    if (corpora !== undefined){
        items = corpora.items.map(c => <CorpusSummary key={c.id} corpus={c} onEdit={redirectToEdit} />)
    }

    if (corpusCount === undefined){
        return (
            <ContentScroller>
                <Container>
                    <Row>
                        <Col><Spinner animation="border"/></Col>
                    </Row>
                </Container>
            </ContentScroller>
        )
    }

    return (
        <>
            <div>
                <Container>
                    <Row>
                        <Col>
                            <CorporaHeader corpusCount={corpusCount} onAddNewCorpus={addNew}/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ContentScroller>
                <Container>
                    <Row xl={3} lg={2} md={1} sm={1} xs={1}>
                        {items}
                    </Row>
                </Container>
            </ContentScroller>
        </>
    );
}

function mapStateToProps(state) {
    return {
        tenant: state.tenant.selectedTenant
    }
}

export default connect(mapStateToProps)(ListCorpora);