import React from 'react';
import { connect } from "react-redux";
import {ajaxWatched, apiRequest} from "../../shared/services";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { taskStarted } from "../../shared/actions/task";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import ContentScroller from "../../shared/components/ContentScroller";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { parseLinks } from "../../shared/services";
import { friendlyFormat } from "../../shared/utils";

function queryDocs(docTypes, searchText, metaFilter, callback) {
    docTypes = docTypes.join(',');
    if (searchText != null || searchText.length > 0){
        searchText = '&text=' + encodeURIComponent(searchText);
    } else
    {
        searchText = '';
    }

    let filterText = Object.entries(metaFilter).reduce((a, i) => '&mf=' + encodeURIComponent(i[0]) + '&mv=' + encodeURIComponent(i[1]), '');

    let headers = undefined;
    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, '/docs?docTypes=' + docTypes + searchText + filterText)
                .then(response => {headers = response.headers; return response.json()})
                .then(json => callback({
                    totalCount: parseInt(headers.get('Total-Count')),
                    links: parseLinks(headers),
                    docs: json.result.items
                }))
        ))
    }
}

function getPage(url, callback) {
    let headers = undefined;

    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, url)
                .then(response => {headers = response.headers; return response.json()})
                .then(json => callback({
                    totalCount: parseInt(headers.get('Total-Count')),
                    links: parseLinks(headers),
                    docs: json.result.items
                }))
        ))
    }
}

function getDocTypeCounts(callback) {
    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, '/doc-types?do=counts')
                .then(response => response.json())
                .then(json => callback(json.result))
        ))
    }
}

function upload(docType, file, onFinishedCallback) {
    let formData = new FormData();
    formData.append('file', file);
    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, `/docs?do=import&docType=${docType}`,{
                    method: 'POST',
                    body: formData,
                    headers: {}})
                .then(response => response.json())
                .then(json => dispatch(taskStarted(json.value, onFinishedCallback)))
        ));
    }
}

const TaskResult = props => {
    let r = props.result;
    let s = r.result; // stats
    let failed = Object.keys(s.failed);
    if (failed.length > 0)
    {
        return (
            <>
            <div>Failed: ({failed.length}/{s.processed}) - {s.failed[failed[0]]}</div>
            <div>Duration: {s.duration} ({s.rate.toFixed(3)} docs per second)</div>
            </>
        )
    }
    else {
        return (
            <>
            <span>Done - Inserted: {s.inserted}/{s.processed}, Updated: {s.updated}/{s.processed}, failed: {failed.length}/{s.processed} </span>
            <span>Duration: {s.duration} ({s.rate.toFixed(3)} docs per second)</span>
            </>
        );
    }
}

class ImportModal extends React.Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        this.docTypeSelect = React.createRef();
        this.state = {
            importing: false,
            lastResult: null
        }
    }

    afterImport = (taskResult) => {
        this.setState({
            importing: false,
            lastResult: taskResult
        });
    }

    doImport = () => {
        if (this.fileInput.current.files.length !== 1)
            // TODO: Display a message to the user
            return;

        let docType = this.docTypeSelect.current.value;
        let file = this.fileInput.current.files[0];
        this.setState({
            importing: true
        });
        this.props.dispatch(upload(docType, file, this.afterImport));
    }

    render() {
        let options = [...this.props.tenant.docTypes].sort((a, b) => a.label < b.label ? -1 : 1).map(dt => <option key={dt.name} value={dt.name}>{dt.label}</option>);
        return (
            <Modal show={this.props.show} onHide={() => this.props.onHide()}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><em>Required Columns:</em> source_id, title, content, content_type, encoding</p>
                    <input type="file" ref={this.fileInput} />
                    <select ref={this.docTypeSelect}>
                        {options}
                    </select>
                    <Row>
                    {this.state.lastResult != null ? <TaskResult result={this.state.lastResult} /> : ""}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={this.state.importing} onClick={() => this.doImport()}>Import {this.state.importing ? <Spinner animation="border" size="sm" /> : ""}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const MetaDetails = props => {
    try {
        let meta = Object.entries(props.value.meta).sort((a, b) => a[0] - b[0]);
        meta.unshift(['sourceId', props.value.sourceId]);

        let rows = meta.map((kvp, i) => <tr key={kvp[0]}>
            <td className="align-top">{kvp[0]}:</td>
            <td style={{cursor:'pointer'}} onClick={(e) => props.onFilterClicked(kvp[0], kvp[1])} >{kvp[1] === null ? '' : kvp[1].toString()}</td>
        </tr>);


        return <table><tbody>{rows}</tbody></table>
    }
    catch(e) {
        return <p>error</p>
    }
}

const DocumentItemView = props => {
    let doc = props.value;

    return (
        <>
        <Row>
            <Col sm={7}>
                <Row><Col><b>{doc.docType}</b> - {doc.id}</Col></Row>
                <Row><Col><em>{doc.title}</em></Col></Row>
                <Row><Col><pre>{doc.content}</pre></Col></Row>
            </Col>
            <Col sm={5}><MetaDetails onFilterClicked={(k, v) => props.onFilterClicked(doc.docType, k, v)} value={doc} /></Col>
        </Row>
        <hr />
        </>
    )
}

const DocumentsList = props => {
    if (props.value == null) {
        return <></>
    }

    if (props.value.length === 0) {
        return <p>No Documents</p>
    }

    let docs = props.value.map(doc => <DocumentItemView onFilterClicked={props.onFilterClicked} key={doc.id} value={doc} />);

    return (
        <Container>
            {docs}
        </Container>
    )
}

class DocsDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ndocs: 0,
            importing: false,
            docTypeCounts: null,
            selectedDocTypes: [],
            cols: [{key: 'title', name: 'Title'}, {key: 'content', name: 'Content'}],
            searchText: '',
            links: {},
            metaField: '',
            metaValue: ''
        };
    }

    componentDidMount() {
        this.props.dispatch(getDocTypeCounts((r) => {
            this.setState({docTypeCounts: r, selectedDocTypes: []}, this.performSearch);
        }));
    }

    showDialog = () => {
        this.setState({
            importing: true
        });
    }

    handleOnHide = () => {
        this.setState({
            importing: false
        });

        this.performSearch();
    }

    handleDocTypesSelected = (docTypes) => {
        this.setState({
            selectedDocTypes: docTypes,
            metaField: '',
            metaValue: ''
        }, this.performSearch);
    }

    getDocsCallback = s => {
        this.setState({
            ndocs: s.totalCount,
            docs: s.docs,
            links: s.links
        });
    }

    performSearch = () => {
        let metaFilter = {};

        if (this.state.selectedDocTypes.length === 1 && this.state.metaField && this.state.metaField.length > 0){
            metaFilter[this.state.metaField] = this.state.metaValue;
        }
        this.setState({
            docs: null,
            links: {}
        });

        this.props.dispatch(queryDocs(this.state.selectedDocTypes, this.state.searchText, metaFilter, this.getDocsCallback));
    }

    updateSearchText = (e) => {
        this.setState({
            searchText: e.target.value
        }, this.performSearch)
    }

    loadPage = (url) => {
        this.setState({
            docs: null,
            links: {}
        });
        this.props.dispatch(getPage(url, this.getDocsCallback));
    }

    handleFilterClicked = (docType, metaField, metaValue) => {
        this.setState({
            docs: null,
            links:{},
            selectedDocTypes: [docType],
            metaField: metaField,
            metaValue: metaValue
        }, this.performSearch);
    }

    render() {
        let toggleGroup = '';
        if (this.state.docTypeCounts !== null){
            let btns = this.state.docTypeCounts.sort((a, b) => a.label < b.label ? -1 : 1).map(r => <ToggleButton key={r.docType} className="mx-2" value={r.docType} title={r.count.toLocaleString()} ><h3>{friendlyFormat(r.count)}</h3><p>{r.label}</p></ToggleButton>);
            toggleGroup = <ToggleButtonGroup type="checkbox" value={this.state.selectedDocTypes} onChange={this.handleDocTypesSelected}>{btns}</ToggleButtonGroup>;
        }

        let links = Object.entries(this.state.links).map(i => <Button key={i[0]} value={i[1]} onClick={(e) => this.loadPage(e.target.value)}>{i[0]}</Button>);
        if (links.length > 0) {
            links = <ButtonGroup>{links}</ButtonGroup>
        } else {
            links = ''
        }

        let metaFilters = '';
        if (this.state.selectedDocTypes.length === 1) {
            metaFilters = <Row>
                <Form.Group as={Col} name="metaField">
                    <Form.Label>Meta Field</Form.Label>
                    <Form.Control as="input" value={this.state.metaField}
                                  onChange={({target}) => this.setState({metaField: target.value})}></Form.Control>
                </Form.Group>
                <Form.Group as={Col} name="metaValue">
                    <Form.Label>Value</Form.Label>
                    <Form.Control as="input" value={this.state.metaValue}
                                  onChange={({target}) => this.setState({metaValue: target.value})}></Form.Control>
                </Form.Group>
            </Row>;
        }

        return (
            <>
                <div>
                    <Container>
                        <Form>
                            <Form.Group as={Row} name="stats">
                                <h3>{this.state.ndocs === null ? <Spinner animation="border"/> : this.state.ndocs.toLocaleString()} documents</h3>
                                <Button variant="link" onClick={this.showDialog}>Upload more</Button>
                            </Form.Group>
                            <Form.Group as={Row} name="docTypes">
                                {toggleGroup}
                            </Form.Group>
                            {metaFilters}
                            <Form.Group as={Row} name="searchText">
                                <Form.Label>Search</Form.Label>
                                <Form.Control as="textarea" rows={2} defaultValue={this.state.searchText} onBlur={this.updateSearchText}/>
                            </Form.Group>
                            <Form.Group as={Row} name="paging">
                                {links}
                            </Form.Group>
                        </Form>
                        <ImportModal {...this.props} show={this.state.importing} onHide={this.handleOnHide}/>
                    </Container>
                </div>
                <ContentScroller>
                    <Container>
                        <Row>
                            {this.state.docs === null ? <Spinner animation="border" /> : <DocumentsList onFilterClicked={this.handleFilterClicked} value={this.state.docs} /> }
                        </Row>
                        <Row>
                            {links}
                        </Row>
                    </Container>
                </ContentScroller>
            </>
        )

    }
}

function mapStateToProps(state){
    return {
        tenant: state.tenant.selectedTenant
    }
}

export default connect(mapStateToProps)(DocsDashboard);