import React, { useState } from 'react';
import { connect } from "react-redux";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { apiRequest, ajaxWatched } from "../../shared/services";
import { selectTenant } from "../../shared/actions/tenant";
import DocTypeEditor from "./DocTypeEditor";
import ContentScroller from "../../shared/components/ContentScroller";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function saveTenant(tenant, callback) {
    return (dispatch, getState) => {
        let body = JSON.stringify(tenant);
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, '',{
                    method: 'PUT',
                    body: body
                })
                .then(response => response.status === 204 ? null : response.json())
                .then(json => dispatch(selectTenant(tenant.id)))
                .then(response => {if (callback !== undefined) { callback(response); }})
        ))
    }
}

const TenantEditor = props => {
    let t = props.tenant;

    const deepCopyDocTypes = (docTypes) => {
        return docTypes.map(dt => {
            return ({
                ...dt,
                properties: {
                    ...dt.properties,
                    columns: [...dt.properties.columns],
                    rows: dt.properties.rows.map(r => [...r])
                }
            });
        });
    }

    let firstDocType = undefined;
    if (t.docTypes.length > 0){
        firstDocType = t.docTypes[0].name;
    }

    const [name, setName] = useState(t.name);
    const [notes, setNotes] = useState(t.internalNotes);
    const [docTypes, setDocTypes] = useState(deepCopyDocTypes(t.docTypes));
    const [selectedDocType, setSelectedDocType] = useState(firstDocType);

    const save = function(e) {
        e.preventDefault();
        t.name = name;
        t.internalNotes = notes;
        t.docTypes = docTypes;
        props.dispatch(saveTenant(t, r => alert('Saved')));
    }

    const addNewDocType = () => {
        let cnt = docTypes.reduce((v, dt) => v + 1, 0);
        let docType = {
            name: 'new-doc-type-' + cnt.toString(),
            label: 'New Doc Type ' + cnt.toString(),
            description: null,
            addtlProps: false,
            properties: {
                columns: ['Property', 'Type', 'Format', 'Required'],
                rows: []
            }
        }
        let copy = docTypes.slice()
        copy.push(docType)
        setDocTypes(copy);
        setSelectedDocType(docType.name);
    }

    const handleDocTypeChanged = (dt, i) => {
        let copy = docTypes.slice();
        copy[i] = dt;
        setDocTypes(copy);
    }

    const handleRemoveDocType = (dt, i) => {
        let copy = docTypes.slice();
        copy.splice(i, 1);
        setDocTypes(copy);
        if (copy.length > 0) {
            if (i > copy.length - 1) {
                i = copy.length - 1;
            }
            setSelectedDocType(copy[i].name)
        }
    }

    let doc_types = docTypes.map((dt, i) => (
        <Tab key={dt.name} eventKey={dt.name} title={dt.label}>
            <DocTypeEditor key={dt.name} docType={dt}
                           onChange={val => handleDocTypeChanged(val, i)}
                           onRemove={dt => handleRemoveDocType(dt, i)}
            />
        </Tab>
    ));

    return (
        <ContentScroller>
            <Container>
                <Row>
                    <Col>
                        <Form>
                            <Form.Group controlId="id">
                                <Form.Label>ID</Form.Label>
                                <Form.Text><em>{t.id}</em></Form.Text>
                            </Form.Group>
                            <Form.Group controlId="name">
                                <Form.Label>Friendly Name</Form.Label>
                                <Form.Control type="text" defaultValue={name} onChange={e => setName(e.target.value)} />
                            </Form.Group>
                            <Form.Group controlId="internalNotes">
                                <Form.Label>Internal Notes</Form.Label>
                                <Form.Control type="text" defaultValue={notes} onChange={e => setNotes(e.target.value)}/>
                            </Form.Group>
                            <Form.Group controlId="docTypes">
                                <Form.Label>Document Types</Form.Label>
                                <Tabs activeKey={selectedDocType} onSelect={e => setSelectedDocType(e)}>
                                    {doc_types}
                                </Tabs>
                            </Form.Group>
                            <Form.Group controlId="docTypeActions">
                                <Form.Control type="button" value="Add New Doc Type" onClick={addNewDocType} />
                            </Form.Group>
                            <Form.Group controlId="save">
                                <Form.Control type="button" defaultValue="Save Tenant" onClick={save} />
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </ContentScroller>);
};

function mapStateToProps(state) {
    return {
        'tenant': state.tenant.selectedTenant
    };
}

export default connect(mapStateToProps)(TenantEditor);