import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import Container from "react-bootstrap/Container";
import ContentScroller from "../../shared/components/ContentScroller";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { apiRequest } from "../../shared/services";
import Spinner from "react-bootstrap/Spinner";
import DataGrid, { SelectColumn } from 'react-data-grid';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import AnalysisOutputTable from "../analysis/AnalysisOutputTable";
import AnalysisOutputChart from "../analysis/AnalysisOutputChart";
import Button from "react-bootstrap/Button";
import 'react-data-grid/dist/react-data-grid.css';


function getPeriods(modelDefId, callback) {
    let resource = `/model-defs/${encodeURIComponent(modelDefId)}?do=periods`;
    return (dispatch, getState) => {
        apiRequest(dispatch, getState, resource)
            .then(response => response.json())
            .then(json => callback(json.result.items));
    }
}

function getTopTerms(modelDefId, period, callback) {
    let resource = `/model-defs/${encodeURIComponent(modelDefId)}?do=topterms&period=${encodeURIComponent(period)}&take=2000`;
    return (dispatch, getState) => {
        apiRequest(dispatch, getState, resource)
            .then(response => response.json())
            .then(json => callback(json.result.items));
    }
}

function getTimeline(modelDefId, period, terms, metric, callback) {
    terms = terms.map(t => encodeURIComponent(t)).join(',');
    let resource = `/model-defs/${encodeURIComponent(modelDefId)}` +
                   `?do=timeline&period=${encodeURIComponent(period)}&terms=${terms}&metric=${encodeURIComponent(metric)}`;

    return (dispatch, getState) => {
        apiRequest(dispatch, getState, resource)
            .then(response => response.json())
            .then(json => { callback(json.result); });
    }
}

const TextFilterRenderer = p => {
    return (<div className="tdg-filter-container">
        <input
            className="tdg-filter"
            type="text"
            value={p.value}
            onChange={e => p.onChange(e.target.value)}
            style={{height: '100%'}}
        />
    </div>);
};

const TermSelector = ({terms, onSelectionChanged}) => {
    const [[sortColumn, sortDirection], setSort] = useState(['term', 'NONE']);
    const [rows, setRows] = useState(terms.map(t => ({term: t[0], count: t[1]})));
    const [selectedRows, setSelectedRows] = useState(() => new Set());
    const [filters, setFilters] = useState({term: ''})

    useEffect(() => {
        if (onSelectionChanged !== null){
            onSelectionChanged([...selectedRows]);
        }
    }, [selectedRows, onSelectionChanged]);

    useEffect(() => {
        let copy = terms.filter(r => filters['term'].length === 0 || r[0].indexOf(filters['term']) >= 0)
            .map(t => ({term: t[0], count: t[1]}));
        setRows(copy);
    }, [filters, terms]);

    const columns = ['Term', 'Count'].map(c => ({key:c.toLowerCase(), name:c}));
    columns[0].filterRenderer = TextFilterRenderer;
    columns.unshift(SelectColumn);

    const handleSort = (sortColumn, sortDirection) => {
        const comparer = (a, b) => {
            if (sortDirection === 'ASC'){
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } else if (sortDirection === 'DESC') {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
        };

        if (sortDirection !== 'NONE'){
            setRows(rows.sort(comparer));
        }

        setSort([sortColumn, sortDirection])
    }

    return (<DataGrid
        columns={columns}
        rows={rows}
        defaultColumnOptions={{
            sortable: true,
            resizable: true
        }}

        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={handleSort}

        rowKeyGetter={r => r['term']}
        selectedRows={selectedRows}
        onSelectedRowsChange={setSelectedRows}

        enableFilterRow={true}
        filters={filters}
        onFiltersChange={setFilters}


        className="fill-grid"
        style={{resize:'both', width:'100%'}}
    />)
}


const TemporalModelExplorer = props => {
    const [metric, setMetric] = useState('raw-count');
    const [period, setPeriod] = useState(undefined);
    const [periods, setPeriods] = useState(undefined);
    const [terms, setTerms] = useState(undefined);
    const [selectedTerms, setSelectedTerms] = useState([]);
    const [resultsView, setResultsView] = useState('chart');
    const [timeline, setTimeline] = useState(undefined);

    useEffect(() => {
        if (periods === undefined) {
            props.dispatch(getPeriods(props.modelDef.id, items => {
                setPeriods(items);
                setPeriod(items[0]);
            }));
        } else if (period !== undefined && terms === undefined){
            props.dispatch(getTopTerms(props.modelDef.id, period, setTerms));
        }
    }, [periods, period, terms, props]);

    if (periods === undefined || period === undefined || terms === undefined){
        return <Spinner animation="border" />
    }

    let periodOptions = periods.map(p => <option key={p} value={p}>{p}</option>);

    const executeSearch = () => {
        if (selectedTerms.length === 0) {
            setTimeline(undefined);
        } else
        {
            props.dispatch(getTimeline(props.modelDef.id, period, selectedTerms, metric, setTimeline));
        }
    }

    const buildResultsView = () => {
        if (timeline === undefined) {
            return <></>
        }

        let dateColIdx = timeline.columns.indexOf('date');
        let valueCols = timeline.columns.slice(dateColIdx + 1);

        //return <AnalysisOutputTable results={timeline} />
        return (<Tabs activeKey={resultsView} onSelect={setResultsView}>
                    <Tab key="chart" title="Chart" eventKey="chart">
                        <AnalysisOutputChart
                            results={timeline}
                            xAxisKey={'date'}
                            dataSeries={valueCols}
                        />
                    </Tab>
                    <Tab key="table" title="Table" eventKey="table">
                        <AnalysisOutputTable results={timeline} />
                    </Tab>
                </Tabs>)
    }

    return (
        <>
            <div>
                <Container>
                    <div>Temporal Model Explorer: {props.modelDef.id}({props.modelDef.activeModelId})</div>
                </Container>
            </div>
            <ContentScroller>
                <Container>
                    <Row>
                        <Col>
                            <Form>
                            <Row>
                                <Col>
                                    <Form.Group as={Row}>
                                        <Form.Label>Select Terms</Form.Label>
                                        <TermSelector
                                            terms={terms}
                                            onSelectionChanged={setSelectedTerms}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group as={Row}>
                                        <Form.Label>Display Metric</Form.Label>
                                        <Form.Control as="select" defaultValue={metric} onChange={e => setMetric(e.target.value)}>
                                            <option value="raw-count">Raw Count</option>
                                            <option value="tf">Relative Frequency</option>
                                            <option value="tfidf">Importance</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label>Period</Form.Label>
                                        <Form.Control as="select" defaultValue={period} onChange={e => setPeriod(e.target.value)}>
                                            {periodOptions}
                                        </Form.Control>
                                    </Form.Group>
                                    <Row>
                                        <Button onClick={executeSearch} variant="primary">Refresh</Button>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        {buildResultsView()}
                        </Col>
                    </Row>
                </Container>
            </ContentScroller>
        </>
    )
}

function maptStateToProps(state) {
    return {}
}

export default connect(maptStateToProps)(TemporalModelExplorer);