import { TENANT_SELECTED } from "../actions/tenant";

const initState = {
    selectedTenant: null
};

export default function tenantReducer(tenant=initState, action) {
    if (TENANT_SELECTED === action.type) {
        tenant = {
            ...tenant,
            selectedTenant: action.tenant
        };
    }

    return tenant;
}