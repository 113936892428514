import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ContentScroller from "../../shared/components/ContentScroller";
import {beginModelTask, findMany, runPipeline, deleteModelDef} from "../../shared/services";
import {ButtonGroup, Container, DropdownButton, Dropdown, Spinner} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Redirect } from "react-router-dom";
import Button from "react-bootstrap/Button";


function mapStateToProps(state) {
    return {
        tenant: state.tenant.selectedTenant,
        user: state.user
    }
}

const ModelDefSummary = connect(mapStateToProps)(function(props) {
    let [redirect, setRedirect] = useState(undefined);
    let md = props.modelDef;

    if (redirect !== undefined){
        return <Redirect to={redirect} />
    }

    const searchModel = function(md) {
        setRedirect(`/helm/t/${props.tenant.id}/models/${md.id}/search`);
    }

    const handleTaskSelected = function(eventKey, e) {
        switch(eventKey){
            case "edit":
                props.onEdit(md);
                break;
            case "analyze_terms_per_aggregation":
                props.dispatch(beginModelTask(props.tenant.id, md.id, 'analyze_terms_per_aggregation'));
                break;
            case "delete_model_def":
                if (window.confirm('You\'re about to delete "' + md.name + '".\r\nAre you sure?'))
                {
                    props.dispatch(deleteModelDef(props.tenant.id, md.id, () => {
                        props.onDeleted();
                    }))
                }
                break;
            default:
                let pl = md.relatedPipelines.filter(pl => pl.key === eventKey)[0];
                let config = pl.defaultConfig;
                props.dispatch(runPipeline(props.tenant.id, md.id, config, (json) => {alert(eventKey + " finished with status: " + json.status)}));
                break;
        }
    }

    const lookupCorpusName = function(corpusId) {
        try {
            let corpora = props.corpora;
            if (corpora !== undefined) {
                for (var c = 0; c < corpora.length; c++) {
                    if (corpusId === corpora[c].id) {
                        return corpora[c].name;
                    }
                }
            }
        } catch(e) { console.log(e); }

        return corpusId;
    }

    let relatedTaskItems = md.relatedPipelines.map(pt => <Dropdown.Item key={pt.key} eventKey={pt.key} title={pt.description}>{pt.label}</Dropdown.Item>);

    let lastIndexHistory = undefined;
    if (md.modelHistories.length > 0){
        lastIndexHistory = md.modelHistories[md.modelHistories.length - 1];
    } else {

    }

    let secondaryActions = [];
    if (['admin', 'basic'].includes(props.user.caps.role)){
        secondaryActions.push(<Dropdown.Item eventKey="edit">Edit</Dropdown.Item>);
        secondaryActions.push(<Dropdown.Item eventKey="analyze_terms_per_aggregation">Key Terms per Agg (depricated)</Dropdown.Item>);
    }
    secondaryActions.push(relatedTaskItems);
    if (['admin', 'basic'].includes(props.user.caps.role)) {
        secondaryActions.push(<Dropdown.Item eventKey="delete_model_def">Delete this Model Def</Dropdown.Item>)
    }

    secondaryActions = (secondaryActions.length > 0) ? (
        <DropdownButton as={ButtonGroup} title="" onSelect={handleTaskSelected}>
            {secondaryActions}
        </DropdownButton>
    ) : (<></>);

    return (
        <Card className="my-4">
            <Card.Header as="h5">
                {md.name}
                <ButtonGroup className="float-right">
                    <Button onClick={() => searchModel(md)}>Search</Button>
                    {secondaryActions}
                </ButtonGroup>
            </Card.Header>
            <Card.Body>
                <Container>
                    <Row>
                        <Col>{md.description}</Col>
                        <Col>
                            <Container>
                                <Row>
                                    <Col className="col-md-4">Is Published?</Col>
                                    <Col className="col-md-8">{md.isPublished ? "Yes" : "No"}</Col>
                                </Row>
                                <Row>
                                    <Col className="col-md-4">Model Type</Col>
                                    <Col className="col-md-8">{md.pipelineConfig.pipelineType}</Col>
                                </Row>
                                <Row>
                                    <Col className="col-md-4">Corpus</Col>
                                    <Col className="col-md-8" title={md.corpusId}>{lookupCorpusName(md.corpusId)}</Col>
                                </Row>
                                <Row>
                                    <Col className="col-md-4">Active Model</Col>
                                    <Col className="col-md-8">{md.activeModelId}</Col>
                                </Row>
                                <Row>
                                    <Col className="col-md-4">Is Dirty?</Col>
                                    <Col className="col-md-8">{md.isDirty ? "Yes" : "No"}</Col>
                                </Row>

                                <Row>
                                    <Col className="col-md-4">Index Status?</Col>
                                    <Col className="col-md-8">{md.indexStatus}</Col>
                                </Row>
                                <Row>
                                    <Col className="col-md-4">Last Indexed</Col>
                                    <Col className="col-md-8" title={lastIndexHistory ? "executed by: " + lastIndexHistory.executedBy : null}>{lastIndexHistory ? new Date(lastIndexHistory.runTimestampUtc).toLocaleString() : ""}</Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    )
});

const ListModelDefs = props => {
    const [modelDefs, setModelDefs] = useState(undefined);
    const [corpora, setCorpora] = useState(undefined);
    const [modelDefCount, setModelDefCount] = useState(0);
    const [redirect, setRedirect] = useState(undefined);

    const refresh = () => {
        props.dispatch(findMany('model-defs', (result, cnt) =>{
            setModelDefs(result);
            setModelDefCount(cnt);
        }))
    };

    useEffect(() => {
        if (modelDefs === undefined){
            refresh();
        }

        if (corpora === undefined) {
            props.dispatch(findMany('corpora', (result, cnt) =>{
                setCorpora(result);
            }));
        }
    })

    if (redirect !== undefined){
        return <Redirect to={redirect} />
    }

    if (modelDefs === undefined || corpora === undefined){
        return <ContentScroller>
            <Container>
                <Row>
                    <Col><Spinner animation="border" /></Col>
                </Row>
            </Container>
        </ContentScroller>
    }

    const handleOnEdit = function(modelDef) {
        setRedirect(`./model-defs/${modelDef.id}`);
    }

    let summaries = modelDefs.map((md, i) => <ModelDefSummary key={i} tenant={props.tenant} modelDef={md} corpora={corpora} onEdit={handleOnEdit} onDeleted={refresh}/>)

    let addNewButton = (['admin', 'basic'].includes(props.user.caps.role)) ? (<Button onClick={() => setRedirect('./model-defs/new')}>Add New</Button>) : <></>;

    return (
        <>
            <div>
                <Container>
                    <Row>
                        <Col>
                            <h5>{modelDefCount} Model Definitions</h5>
                            {addNewButton}
                        </Col>
                    </Row>
                </Container>
            </div>

            <ContentScroller>
                <Container>
                    <Row>
                        <Col>
                            {summaries}
                        </Col>
                    </Row>
                </Container>
            </ContentScroller>
        </>
    )
}

export default connect(mapStateToProps)(ListModelDefs);