import React from "react";

const ContentScroller = (props) => {
    let style = undefined;
    if ('style' in props) {
        style = props.style;
    }
    return (
        <div className='layout-content-scroller' style={style}>
            <div className='layout-content'>
                {props.children}
            </div>
        </div>
    )
}

export default ContentScroller;