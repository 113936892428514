import React from 'react';
import ContentScroller from "../../shared/components/ContentScroller";

const Home = props => {
    return (
        <ContentScroller>
            <div>Welcome! Get Started by choosing a menu item to the left.</div>
        </ContentScroller>);
};

export default Home;