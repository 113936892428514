import { USER_AUTHENTICATED } from '../actions/user'

const initState = {
    token: null
};

export default function userReducer(user=initState, action) {
    if (USER_AUTHENTICATED === action.type) {
        user = {
            ...user,
            auth_token: action.auth_token,
            caps: action.caps,
            credentials: action.credentials,
            lastAuth: Date.now()
        };
    }
    return user;
}