import React from "react";
import { connect } from 'react-redux';

const Header = props => {

    let title = 'Loading...';

    if (props.tenant !== null){
        title = props.tenant.name;
    }

    return (
        <div className='layout-header'>
            <div className='header-title'>
                <h1>{title}</h1></div>
            <div className='header-menu'>
                {/*<span>Notifications</span>
                <span>Account</span>*/}
                Welcome {props.user.caps.api_key}!
            </div>
        </div>
    );
};

function mapStateToProps(state){
    return {
        tenant: state.tenant.selectedTenant,
        user: state.user
    };
}

export default connect(mapStateToProps)(Header);