import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import {findOne} from "../../shared/services";
import Spinner from "react-bootstrap/Spinner";
import SimilarityModelExplorer from "./SimilarityModelExplorer";
import TemporalModelExplorer from "./TemporalModelExplorer"
import DocumentClassifierModelExplorer from "./DocumentClassifierModelExplorer";
import DefaultModelExplorer from "./DefaultModelExplorer";


const ModelSearch = props => {
    const [modelDef, setModelDef] = useState(undefined);

    useEffect(() => {
        if (modelDef === undefined) {
            props.dispatch(findOne('model-defs', props.match.params.modelDefId, setModelDef));
        }
    }, [modelDef, props])

    if (modelDef === undefined){
        return <Spinner animation="border" />
    }

    switch(modelDef.pipelineConfig.pipelineType){
        case "71dc4374-3b94-4f0e-801c-d757faff2fe4":
            return <SimilarityModelExplorer modelDef={modelDef} />
        case "5dc06eeb-078f-44f8-bca8-397055476a7d":
            return <TemporalModelExplorer modelDef={modelDef} />
        case "c950fd4a-f5c1-4c36-b79f-ae570f03f414":
            return <DocumentClassifierModelExplorer modelDef={modelDef} />
        default:
            return <DefaultModelExplorer modelDef={modelDef} />
    }
}

function mapStateToProps(state){
    return {};
}

export default connect(mapStateToProps)(ModelSearch);