import React, { useState } from 'react'
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import ContentScroller from "../../shared/components/ContentScroller";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {ajaxWatched, apiRequest} from "../../shared/services";
import AnalysisOutputTable from "../analysis/AnalysisOutputTable";
import AnalysisOutputChart from "../analysis/AnalysisOutputChart";


export function getPrediction(modelDefId, title, content, callback) {
    let body = JSON.stringify({
        title: title,
        content: content
    });
    let resource = `/model-defs/${encodeURIComponent(modelDefId)}?do=predict`;
    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, resource, {
                method: 'POST',
                body: body
            })
            .then(response => response.json())
            .then(json => callback(json.result))
        ))
    }
}

const DocumentClassifierModelExplorer = props => {
    const [title, setTitle] = useState('Electrical Damage');
    const [content, setContent] = useState('Anyone seen bearing spragging on their machine?');
    const [results, setResults] = useState(undefined);

    const executeSearch = () => {
        props.dispatch(getPrediction(props.modelDef.id, title, content, setResults));
    }

    let resultsView = <></>;
    if (results !== undefined){
        resultsView = (
            <Container>
                <Row><Col>Predicted Class</Col></Row>
                <Row><Col><h4>{results['predictedClass']}</h4></Col></Row>
                <Row>
                    <Col md={8}>
                        <Row><Col>Top Classes</Col></Row>
                        <Row><Col><AnalysisOutputChart renderAs="vbar" results={results['classProbabilities']} xAxisKey="Class" dataSeries={["Probability"]}/></Col></Row>
                    </Col>
                    <Col md={4}>
                        <Row><Col>Common Terms for {results['predictedClass']}</Col></Row>
                        <Row><Col><AnalysisOutputTable results={results['classDefinitions'][results['predictedClass']]}/></Col></Row>
                    </Col>
                </Row>
            </Container>);
    }

    return (
        <>
            <div>
                <Container>
                    <Row>
                        <Col><h4>{props.modelDef.name}</h4></Col>
                    </Row>
                    <Row>
                        <Col>Document Classifier Model Explorer: {props.modelDef.id}({props.modelDef.activeModelId})</Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form>
                                <Form.Group as={Row}>
                                    <Col>
                                        <Form.Control type="text" placeholder="title" defaultValue={title} onChange={e => setTitle(e.target.value)}></Form.Control>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Col>
                                        <Form.Control as="textarea" rows={4} placeholder="content" defaultValue={content} onChange={e => setContent(e.target.value)}></Form.Control>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col><Button onClick={executeSearch}>Search</Button></Col>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ContentScroller>
                {resultsView}
            </ContentScroller>
        </>
    )
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(DocumentClassifierModelExplorer);