import React from 'react';
import { connect } from 'react-redux';
import './layout.css';

const Layout = props => {
    let { children } = props;

    return (
        <>
            {children}
        </>
    );
}

function mapStateToProps(state){
    return {};
}

export default connect(mapStateToProps)(Layout);