import React, { useState } from 'react';
import { connect } from "react-redux";
import { apiRequest, ajaxWatched } from "../../shared/services";
import Spinner from "react-bootstrap/Spinner";
import ContentScroller from "../../shared/components/ContentScroller";
import AnalysisOutputTable from "./AnalysisOutputTable";
import Button from "react-bootstrap/Button";
import { exportAnalysisResults } from "../../shared/actions/analysisOutputs";

function getAnalysisOutput(id, callback) {
    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, `/analysis-outputs/${id}`,{})
                .then(response => response.json())
                .then(json => callback(json.result))
        ))
    }
}

const AnalysisOutputViewer = props => {
    const [result, setResult] = useState(null);

    if (result === null){
        props.dispatch(getAnalysisOutput(props.match.params.id, setResult));
        return <ContentScroller><Spinner animation="border" /></ContentScroller>
    }

    if (result.resultType === "AnalysisResult") {
        return (
            <ContentScroller>
                <style type="text/css"
                       dangerouslySetInnerHTML={{__html: `.layout-content, .react-grid-Container,  .react-grid-Main, .react-grid-Grid { height: 100%; }`}}></style>
                <AnalysisOutputTable results={result.results}/>
            </ContentScroller>)
    }

    return (
        <ContentScroller>
            <Button variant="outline-secondary" className="mx-1"
                            onClick={e => props.dispatch(exportAnalysisResults(result.id))}>Download File</Button>
        </ContentScroller>
    )
};

function mapStateToProps(state) {
    return {
        tenant: state.tenant.selectedTenant
    };
}

export default connect(mapStateToProps)(AnalysisOutputViewer);
