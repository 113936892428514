import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import ContentScroller from "../../shared/components/ContentScroller";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import {findMany, findOne, updateOne, createOne, ajaxWatched, apiRequest} from "../../shared/services";
import { DocumentSelectorEditor } from './CorpusEditor';
import { refreshSearchConfig } from './DefaultModelExplorer';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import { Redirect } from "react-router-dom";


function refreshPipelineConfig(modelDef, changeAction, currentSettings, currentSettingsDefinition, callback) {
    let res = `/pipeline-configs/${modelDef.pipelineConfig.pipelineType}`;
    let payload = {
        modelDef: modelDef,
        changeAction: changeAction,
        currentSettings: currentSettings,
        currentSettingDefinition: currentSettingsDefinition
    };

    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, res, {
                method: 'PUT',
                body: JSON.stringify(payload)
            })
        ))
            .then(response => {
                return response.json();
            })
            .then(json => {
                callback(json.result);
            })
    }
}

const ObjectEditor = props => {
    let s = props.setting;
    let sd = props.objectSettingsDef;

    const handleChanged = (key, val, args) => {
        if (props.onChange) {
            let changed = {...s};
            changed[key] = val;
            props.onChange(changed, args);
        }
    }

    let settings = Object.entries(sd)
        .sort((a, b) => a[1].order < b[1].order ? -1 : 1)
        .map(i => <SettingEditor key={i[0]}
                                 setting={s[i[0]]}
                                 settingDef={i[1]}
                                 tenant={props.tenant}
                                 modelDef={props.modelDef}
                                 corpus={props.corpus}
                                 onChange={(val, args) => handleChanged(i[0], val, args)} />);

    return (
        <Row>
            <Col>
                <Row>
                    <Col>
                        {settings}
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

const ObjectListEditor = props => {
    let [key, setKey] = useState(0);
    let items = props.items;
    let sd = props.settingDef;

    const handleChanged = (val, idx) => {
        if (props.onChange){
            let changed = [...items];
            changed[idx] = val;
            props.onChange(changed);
        }
    }

    let editors = [<Tab key={-1} title="+" eventKey={-1} />];
    if (items != null) {
        let itemSd = {
            ...sd
        }
        itemSd.is_list = false;

        editors = items.map((i, idx) => <Tab key={idx} eventKey={idx} title={`Item ${idx+1}`}><SettingEditor key={idx}
               setting={i}
               settingDef={itemSd}
               tenant={props.tenant}
               modelDef={props.modelDef}
               corpus={props.corpus}
               onChange={val => handleChanged(val, idx)}/></Tab>).concat(editors);
    }
    const handleTabSelect = function(k) {
        if (k === '-1'){
            let changed = items != null ? [...items] : [];
            let def = sd.default_value;
            if (sd.object_settings){
                def = Object.entries(sd.object_settings).reduce((obj, kvp) => {obj[kvp[0]] = kvp[1].default_value; return obj}, {})
            }
            changed.push(def);
            setKey(changed.length-1);
            if (props.onChange){
                props.onChange(changed);
            }
        } else {
            setKey(k);
        }
    }

    return (
        <Row className="setting-row">
                <Col>
                    <Row>
                        <Col><h6>{sd.label}</h6></Col>
                    </Row>
                    <Row>
                        <Col>
                            <Tabs activeKey={key} onSelect={handleTabSelect}>
                                {editors}
                            </Tabs>
                        </Col>
                    </Row>
                </Col>
            </Row>
    )
}

const SimpleListEditor = props => {
    //let [key, setKey] = useState(0);
    let selectedItems = props.items;
    let sd = props.settingDef;

    const handleChanged = ({target}) => {
        if (props.onChange){
            let changed = [];
            for (var i = 0; i < target.selectedOptions.length; i++){
                changed.push(target.selectedOptions[i].value);
            }
            props.onChange(changed);
        }
    }

    let options = null;
    if (sd.available_values !== null) {
        options = sd.available_values.map(v => <option key={v}>{v}</option>)
    }

    let defaultValue = []; //sd.default_value;
    if (selectedItems != null) {
        defaultValue = selectedItems;
    }

    // Selects don't accept a list if multiple=False
    if (!sd.allow_multi_select && Array.isArray(defaultValue)) {
        if (defaultValue.length === 0){
            defaultValue = undefined;
        } else
        {
            defaultValue = defaultValue[0];
        }
    }

    let desc = sd.description != null ? <Row><Col><span style={{color: '#999'}}>{sd.description}</span></Col></Row> : null;

    return (
        <Row className="setting-row">
                <Col>
                    <Row>
                        <Col><h6>{sd.label}</h6></Col>
                    </Row>
                    <Row>
                        <Col>
                            <select defaultValue={defaultValue} onChange={handleChanged}
                                    multiple={sd.allow_multi_select}
                                    style={{width: '100%', resize: 'vertical'}}>
                                {options}
                            </select>
                        </Col>
                    </Row>
                    {desc}
                </Col>
            </Row>
    )
}

const ListEditor = props => {
    //let [key, setKey] = useState(0);

    if (props.settingDef.object_settings != null) {
        return <ObjectListEditor items={props.items}
                                 settingDef={props.settingDef}
                                 tenant={props.tenant}
                                 modelDef={props.modelDef}
                                 corpus={props.corpus}
                                 onChange={props.onChange} />
    }
    else {
        return <SimpleListEditor items={props.items}
                                 settingDef={props.settingDef}
                                 tenant={props.tenant}
                                 modelDef={props.modelDef}
                                 corpus={props.corpus}
                                 onChange={props.onChange} />
    }
}

const Checkbox = ({value, onChange}) => {
    return (<input type="checkbox" checked={value} onChange={onChange} />);
}

const DefaultValueEditor = props => {
    let v = props.value;
    let sd = props.settingDef;

    const handleChanged = e => {
        if (props.onChange && e.target.value !== v){
            let v = e.target.value;
            switch(sd.value_type){
                case 'int':
                    v = parseInt(v);
                    break;
                case 'float':
                    v = parseFloat(v);
                    break;
                case 'bool':
                    if (e.target.type === 'checkbox'){
                        v = e.target.checked
                    } else {
                        v = (v.toLowerCase() === 'true');
                    }
                    break;
                default:
                    break;
            }

            props.onChange(v);
        }
    }

    if (sd.value_type === 'bool'){
        console.log('rendering', v)
        return <Checkbox value={v} onChange={handleChanged} />
    }
    else if (sd.is_multiline) {
        return <textarea rows="4" defaultValue={v} style={{width:'100%'}} onBlur={handleChanged} />
    } else {
        let inputType = sd.is_secret ? 'password' : 'text';
        return <input type={inputType} defaultValue={v} style={{width:'100%'}} onBlur={handleChanged} />
    }
}

const SettingEditor = props => {
    let s = props.setting;
    let sd = props.settingDef;

    if (s === undefined){
        return '';
    }

    const handleObjectChanged = obj => {
        if (props.onChange){
            let newValue = {...obj};
            let args = {
                previousValue: s,
                settingDef: sd
            }
            props.onChange(newValue, args);
        }
    }

    const handleValueChange = val => {
        if (props.onChange){
            let newValue = val;
            let args = {
                previousValue: s,
                settingDef: sd
            }

            //props.onChange(val);
            props.onChange(newValue, args);
        }
    }

    if (sd.is_list === true){
        return <ListEditor items={s}
                           settingDef={sd}
                           tenant={props.tenant}
                           modelDef={props.modelDef}
                           corpus={props.corpus}
                           onChange={lst => handleValueChange([...lst])} />
    }

    let desc = sd.description != null ? <Row><Col><span style={{color:'#999'}}>{sd.description}</span></Col></Row> : null;

    if (sd.value_type === 'DocumentSelector'){
        return (
            <Row className="setting-row">
                <Col>
                    <Row>
                        <Col><h6>{sd.label}</h6></Col>
                    </Row>
                    {desc}
                    <Row>
                        <Col>
                            <DocumentSelectorEditor selector={s} tenant={props.tenant} modelDef={props.modelDef} corpus={props.corpus} displayOpts={sd.display_opts} settings={sd} onChange={handleObjectChanged} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    if (sd.object_settings){
        return (
            <Row className="setting-row">
                <Col>
                    <Row>
                        <Col><h6>{sd.label}</h6></Col>
                    </Row>
                    {desc}
                    <Row>
                        <Col>
                            <ObjectEditor setting={s} objectSettingsDef={sd.object_settings} tenant={props.tenant} modelDef={props.modelDef} corpus={props.corpus} onChange={handleObjectChanged} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    return (
        <Row className="setting-row">
            <Col>
                <Row>
                    <Col><h6>{sd.label}</h6></Col>
                </Row>
                <Row>
                    <Col><DefaultValueEditor value={s} settingDef={sd} tenant={props.tenant} modelDef={props.modelDef}
                                             corpus={props.corpus} onChange={handleValueChange} /></Col>
                </Row>
                {desc}
            </Col>
        </Row>
    )
}

const ScopeEditor = props => {
    let sd = props.settingsDef;
    let defaultSettings = Object.entries(sd.settings).reduce((newObj, [key, val]) => {
        newObj[key] = val.default_value;
        return newObj;
    }, {})

    let s = {
        ...defaultSettings,
        ...props.setting
    };

    const handleChanged = (key, val, args) => {
        if (props.onChange){
            let changed = {...s};
            changed[key] = val;

            //props.onChange(changed);
            props.onChange(changed, args);
        }
    }

    let settings = Object.entries(sd.settings)
        .sort((a, b) => a[1].order < b[1].order ? -1 : 1)
        .map(i => <SettingEditor key={i[0]} setting={s[i[0]]} settingDef={i[1]} tenant={props.tenant}
                                 modelDef={props.modelDef} corpus={props.corpus}
                                 onChange={(val, args) => handleChanged(i[0], val, args)}/>);
    return (
        <Form.Row>
            <Form.Group as={Col} controlId={sd.label}>
                <Form.Label><h5>{sd.label}</h5></Form.Label>
                <Row>
                    <Col>{sd.description}</Col>
                </Row>
                <Row>
                    <Col>
                        {settings}
                    </Col>
                </Row>
            </Form.Group>

        </Form.Row>
    )
}

export const PipelineConfigEditor = props => {
    let sd = props.settingDef;
    let s = props.scopeSettings;

    let handleChanged = (key, val, args) => {
        if (props.onChange){
            let changed = {...s};
            changed[key] = val;

            //props.onChange(changed);
            props.onChange(changed, args);
        }
    }

    let scopes = Object.entries(sd)
        .sort((a, b) => a[1].order < b[1].order ? -1 : 1)
        .map(i => <ScopeEditor key={i[0]} setting={s[i[0]]} settingsDef={i[1]} tenant={props.tenant}
                               modelDef={props.modelDef} corpus={props.corpus}
                               onChange={(val, args) => handleChanged(i[0], val, args)}/>);

    return (
        <>
            {scopes}
        </>
    )
}

const ModelDefEditor = props => {
    const [modelDef, setModelDef] = useState(undefined);
    const [modelConfigs, setModelConfigs] = useState(undefined);
    const [selectedConfig, setSelectedConfig] = useState(undefined);
    const [availCorpora, setAvailCorpora] = useState(undefined);
    const [redirect ] = useState(undefined);
    const [selectedCorpus, setSelectedCorpus] = useState(undefined);

    useEffect(() => {
        if (modelDef === undefined){
            if (props.match.params.modelDefId) {
                props.dispatch(findOne('model-defs', props.match.params.modelDefId, md => {
                    setModelDef(md);

                    if (availCorpora !== undefined) {
                        setSelectedCorpus(availCorpora.find(i => i.id === md.corpusId));
                    }
                }));
            } else {
                setModelDef({
                    tenant: props.tenant.id,
                    name: 'New Model Definition',
                    description: null,
                    corpusId: null,
                    activeModelId: null,
                    id: null,
                    modelHistories:[],
                    pipelineConfig: {
                        pipelineType: ''
                    }
                });
            }
        }
        if (modelConfigs === undefined) {
            props.dispatch(findMany('model-configs', (items, cnt) => {
                setModelConfigs(items);
            }))
        }
        if (availCorpora === undefined) {
            props.dispatch(findMany('corpora', (items, cnt) => {
                setAvailCorpora(items);
                if (modelDef !== undefined) {
                    setSelectedCorpus(items.find(i => i.id === modelDef.corpusId));
                }
            }))
        }
    }, [modelDef, modelConfigs, availCorpora, props]);

    useEffect(() => {
        if (selectedConfig == undefined) {
            if (modelConfigs && modelDef) {
                let sc = modelConfigs.filter(c => c.uuid === modelDef.pipelineConfig.pipelineType);
                if (sc.length > 0) {
                    sc = sc[0];
                } else {
                    sc = null;
                }
                setSelectedConfig(sc);
            }
        }
    },[modelDef, modelConfigs])

    useEffect(() => {
        if (selectedCorpus && modelDef && selectedConfig) {
            // TODO: Set loading ui
            props.dispatch(refreshPipelineConfig(
                modelDef,
                '_OnCorpusChanged',
                modelDef.pipelineConfig.settings,
                selectedConfig.settingsDefinition,
                handleRefreshSearchConfig));
        }
    }, [selectedCorpus])


    if (redirect !== undefined){
        return <Redirect to={redirect} />
    }

    if (modelDef === undefined || modelConfigs === undefined || availCorpora === undefined){
        return <Spinner animation="border" />
    }

    const saveModelDef = function() {
        if (modelDef.id) {
            let tmp = {...modelDef};
            delete tmp.relatedPipelines;
            props.dispatch(updateOne('model-defs', tmp, () => {
                alert('saved');
            }))
        } else {
            props.dispatch(createOne('model-defs', modelDef, (id) => {
                let changed = {...modelDef};
                changed.id = id;
                setModelDef(changed);
            }))
        }
    }

    const handleModelDefChanges = function(e) {
        let changed = {
            ...modelDef
        };

        switch (e.target.name) {
            case "isPublished":
                changed[e.target.name] = e.target.checked;
                break;
            default:
                changed[e.target.name] = e.target.value;
                break;
        }

        setModelDef(changed);
    }

    const handleSelectedCorpusChanged = ({target}) => {
        let changed = {
            ...modelDef
        }
        changed['corpusId'] = target.value;
        let corpus = availCorpora.find(i => i.id === target.value)
        setSelectedCorpus(corpus);
        setModelDef(changed);
    }

    const handlePipelineTypeChanged = function(e) {
        let changed = {
            ...modelDef
        };
        
        let pipelineType = e.target.value;
        changed.pipelineConfig.pipelineType = pipelineType;

        if (pipelineType == null || pipelineType === ''){
            changed.pipelineConfig.settings = {};
        } else {
            let config = modelConfigs.filter(c => c.uuid === e.target.value);
            if (config.length > 0) {
                config = config[0];
                changed.pipelineConfig.settings = {...config.defaultSettings};
            }
        }
        setModelDef(changed);
    }

    let pipelineTypes = modelConfigs.map(c => <option key={c.uuid} value={c.uuid}>{c.name}</option>);
    let corpora = availCorpora.map(c => <option key={c.id} value={c.id}>{c.name}</option>);

    const handleRefreshSearchConfig = pipelineType => {
        let newConfig = {...selectedConfig};
        newConfig.settingsDefinition = pipelineType.settingsDefinition;

        setSelectedConfig(newConfig);
    }

    const handleConfigChange = (val, args) => {
        let changed = {
            ...modelDef
        }
        changed.pipelineConfig = {...changed.pipelineConfig};
        changed.pipelineConfig.settings = val;

        setModelDef(changed);

        if (args.settingDef.triggers_change_action !== null) {
            // TODO: Set loading ui
            props.dispatch(refreshPipelineConfig(
                changed,
                args.settingDef.triggers_change_action,
                changed.pipelineConfig.settings,
                selectedConfig.settingsDefinition,
                handleRefreshSearchConfig));
        }
    }

    let editor = '';

    if (selectedConfig){
        editor = <PipelineConfigEditor
            settingDef={selectedConfig.settingsDefinition}
            scopeSettings={modelDef.pipelineConfig.settings}
            tenant={props.tenant}
            modelDef={modelDef}
            corpus={selectedCorpus}
            onChange={handleConfigChange} />
    }

    return (
        <ContentScroller>
            <Container>
                <Row>
                    <Col>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col} controlId="name">
                                    <Form.Label>Model Definition Name</Form.Label>
                                    <Form.Control type="text" name="name" defaultValue={modelDef.name} onBlur={handleModelDefChanges} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label className="float-right">&nbsp;&nbsp;{modelDef.id}</Form.Label>
                                    <Form.Check className="float-right" label="Is Published?" defaultChecked={modelDef.isPublished} name="isPublished" onChange={handleModelDefChanges} />
                                    <Form.Control as="select" defaultValue={modelDef.pipelineConfig.pipelineType} onBlur={handlePipelineTypeChanged}>
                                        {[<option key="" value=""></option>].concat(pipelineTypes)}
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="description">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea" rows={3} name="description" defaultValue={modelDef.description} onBlur={handleModelDefChanges} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="corpusId">
                                    <Form.Label>Selected Corpus</Form.Label>
                                    <Form.Control as="select" defaultValue={modelDef.corpusId} name="corpusId" onBlur={handleSelectedCorpusChanged}>
                                        {[<option key="" value=""></option>].concat(corpora)}
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                            {editor}
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Button onClick={saveModelDef}>Save</Button>
                </Row>
            </Container>
        </ContentScroller>
    )
}

function mapStateToProps(state) {
    return {
        tenant: state.tenant.selectedTenant
    };
}

export default connect(mapStateToProps)(ModelDefEditor);