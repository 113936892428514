import React from 'react';
import { connect } from 'react-redux';

class TaskMonitor extends React.Component {
    render() {
        return <span>{Object.keys(this.props.activeTasks).length} active tasks</span>
    }
}

function mapStateToProps(state) {
    return {
        activeTasks: state.task.activeTasks
    }
}

export default connect(mapStateToProps)(TaskMonitor);