import {TASK_FINISHED, TASK_STARTED, TASK_STATUS_REFRESHED } from "../actions/task";

const initialState = {
    activeTasks: {}
};

export default function taskReducer(task=initialState, action) {
    var newState = undefined;
    switch (action.type) {
        case TASK_STARTED:
            newState = {
                ...task,
                ...{
                    activeTasks: {
                        ...task.activeTasks
                    }
                }
            };
            newState.activeTasks[action.taskId] = {};

            return newState;
        case TASK_FINISHED:
            newState = {
                ...task,
                ...{
                    activeTasks: {
                        ...task.activeTasks
                    }
                }
            };
            delete newState.activeTasks[action.taskId];

            return newState;
        default:
            return task;
    }
}