import {ajaxWatched, apiRequest} from "../services";

export const TENANT_SELECTED = 'TENANT_SELECTED';

export function tenantSelected(json) {
    return {
        type: TENANT_SELECTED,
        tenant: json
    };
}

export function selectTenant(tenantId) {
    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, '', {}, tenantId)
                .then(response => response.json())
                .then(json => dispatch(tenantSelected(json.result)))
        ));
    }
}

