import {ajaxWatched, apiRequest} from "../services";

export function exportAnalysisResults(id) {
    return (dispatch, getState) => {
        let a = document.createElement('a');
        a.style = 'display:none';

        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, `/analysis-outputs/${id}?do=export`,{})
                .then(response => {
                    a.download = response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0];
                    return response.blob();
                })
                .then(blob => {
                    a.href = window.URL.createObjectURL(blob);
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                })
        ))
    }
}