import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import { findOne, updateOne, createOne } from "../../shared/services";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import ContentScroller from "../../shared/components/ContentScroller";


const MetaFilterDetail = props => {
    let f = props.metaFilter;
    let mfs = props.metaFields;

    let options = mfs.map(f => <option key={f[0]} value={f[0]}>{f[0]}</option>);

    const handleChange = function(e) {
        f[e.target.name] = e.target.value;
        if (props.onChange) {
            props.onChange({...f});
        }
    }

    const handleFieldChanged = function(e) {
        let field = e.target.value;
        f.field = field;

        let mf = mfs.filter(i => i[0] === field);
        if (mf.length > 0){
            mf = mf[0];
            f.typeDef = mf[1];
            f.valueFormat = mf[2];
        } else {
            mf = null;
            f.typeDef = null;
            f.valueFormat = null;
        }

        if (props.onChange){
            props.onChange({...f});
        }
    }

    return (
        <Row className="my-1">
            <Col>
                <Form.Control as="select" name="field" defaultValue={f.field} onChange={handleFieldChanged}>
                    {[<option key="" value=""></option>].concat(options)}
                </Form.Control>
            </Col>
            <Col>
                <Form.Control as="select" name="comparison" defaultValue={f.comparison} onChange={handleChange}>
                    <option value=""></option>
                    <option value="=">=</option>
                    <option value="<>">&lt;&gt;</option>
                    <option value="<">&lt;</option>
                    <option value="<=">&lt;=</option>
                    <option value=">">&gt;</option>
                    <option value=">=">&gt;=</option>
                </Form.Control>
            </Col>
            <Col>
                <Form.Control as="input" name="value" defaultValue={f.value}  onChange={handleChange}/>
            </Col>
            <Col>
                <Button onClick={props.onRemove}>X</Button>
            </Col>
        </Row>
    )
}

export const DocumentSelectorEditor = props => {
    let s = props.selector;
    let t = props.tenant;
    if (s == null){
        s = {
            docType: ''
        }
    }

    let availDocTypes = t.docTypes;
    if (props.displayOpts && props.corpus)
    {
        switch (props.displayOpts.docTypeFilter || 'all') {
            case 'reference':
                availDocTypes = availDocTypes.filter(dt => props.corpus.referencedDocTypes.indexOf(dt.name) > -1);
                break;
            case 'selector':
                let sdts = props.corpus.selectors.map(s => s.docType);
                availDocTypes = availDocTypes.filter(dt => sdts.indexOf(dt.name) > -1);
                break;
            case 'all':
                break;
            default:
                throw new Error('Unexpected doc_type_filter');
        }
    }

    let docTypes = [<option key="" value=""></option>].concat(
        availDocTypes.sort((a, b) => a.label < b.label ? -1 : 1).map((dt, i) => <option key={dt.name} value={dt.name}>{dt.label}</option>)
    );
    let docType = undefined;
    let metaFields = [];

    if (s.docType !== null && s.docType !== '') {
        docType = availDocTypes.filter(dt => dt.name === s.docType);
        docType = docType.length === 0 ? undefined : docType[0];
    }

    if (docType !== undefined){
        metaFields = docType.properties.rows.sort((a, b) => a[0] < b[0] ? -1 : 1);
    }

    const handleDocTypeChanged = function(e) {
        if (props.onChange) {
            let changed = {
                ...s
            };
            changed.docType = e.target.value;
            changed.keyFields = ['id'];

            props.onChange(changed);
        }
    }

    const handleKeyFieldsChanged = function(e) {
        if (props.onChange) {
            let changed = {
                ...s
            }

            changed.keyFields = Array.prototype.slice.call(e.target.selectedOptions).map(o => o.value);

            props.onChange(changed);
        }
    }

    const handleFilterChanged = function(val, i) {
        if (props.onChange) {
            let changed = {
                ...s
            }

            changed.metaFilters[i] = val;

            props.onChange(changed);
        }
    }

    const handleRemoveFilter = function(i) {
        if (props.onChange) {
            let changed = {
                ...s
            }

            changed.metaFilters.splice(i, 1);

            props.onChange(changed);
        }
    }

    const addFilter = function(e) {
        if (props.onChange) {

            let changed = {
                ...s
            }

            if (changed.metaFilters === undefined){
                changed.metaFilters = [];
            }

            changed.metaFilters.push({
                field: '',
                comparison: '=',
                value: null
            });
            props.onChange(changed)
        }
    }

    const removeSelector = function() {
        if (props.onRemove){
            props.onRemove(s);
        }
    }

    let metaFilters = s.metaFilters;
    if (metaFilters === undefined) {
        metaFilters = [];
    }
    metaFilters = metaFilters.map((f, i) => <MetaFilterDetail key={i} metaFilter={f} metaFields={metaFields} onChange={val => handleFilterChanged(val, i)} onRemove={val => handleRemoveFilter(i)} />);
    let metaFieldOptions = metaFields.map(i => <option key={i[0]} value={i[0]}>{i[0]}</option>);

    let keyFieldOptions = [
        <option key="" value=""></option>,
        <option key="id" value="id">id</option>,
        <option key="docType" value="docType">docType</option>,
        <option key="srcId" value="srcId">srcId</option>
    ].concat(metaFieldOptions);

    if (props.idKeyOnly === true){
        keyFieldOptions = [
            <option key="id" value="id">id</option>
        ]
    }

    // TODO: Update layout to avoid using <Form />
    return (
        <Form>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>Document Type<Button variant="link" onClick={removeSelector}>remove</Button></Form.Label>
                    <Form.Control as="select" defaultValue={s.docType} onChange={handleDocTypeChanged}>
                        {docTypes}
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Identified By</Form.Label>
                    <Form.Control as="select" defaultValue={s.keyFields} multiple onChange={handleKeyFieldsChanged}>
                        {keyFieldOptions}
                    </Form.Control>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>With this Criteria:</Form.Label>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Col>
                    {metaFilters}
                </Col>
            </Form.Row>
            <Form.Row>
                <Col>
                    <Button onClick={addFilter}>Add Filter</Button>
                </Col>
            </Form.Row>

        </Form>
    )
}

const CorpusEditor = props => {
    const [corpus, setCorpus] = useState(undefined);
    const [sid, setSid] = useState(0); // Assign fake IDs to selectors so we can delete in the middle of the list

    useEffect(() =>{
        if (corpus === undefined){
            if (props.match.params.corpusId) {
                props.dispatch(findOne('corpora', props.match.params.corpusId, c => {
                    for (let i = 0; i < c.selectors.length; i++) {
                        c.selectors[i]['_id'] = i;
                    }
                    setSid(c.selectors.length);
                    setCorpus(c);
                }))
            }
            else {
                setCorpus({
                    tenant: props.tenant.id,
                    name: 'New Corpus',
                    description: null,
                    notes: null,
                    selectors: [],
                    referencedDocTypes: []
                })
            }
        }
    }, [corpus, props])

    if (corpus === undefined){
        return <Spinner animation="border" />
    }

    const handleCorpusChange = function(e) {
        let t = e.target;
        let newCorpus = {
            ...corpus
        }
        newCorpus[t.name] = t.value;

        setCorpus(newCorpus);
    }

    const handleSelectorChanged = function(value, i) {
        let changed = {
            ...corpus
        };
        changed.selectors[i] = value;
        setCorpus(changed);
    }

    const handleRemoveSelector = function(value, i) {
        let changed = {
            ...corpus
        };
        changed.selectors.splice(i, 1);

        setCorpus(changed);
    }

    const handleAddSelector = function() {
        let changed = {
            ...corpus
        }

        changed.selectors.push({
            _id: sid,
            docType: '',
            keyFields: ['id'],
            metaFilters: []
        });

        setSid(sid + 1);

        setCorpus(changed);
    }

    const handleRefDocTypeChanges = ({target}) => {
        let changed = {
            ...corpus
        }

        if (target.checked){
            changed.referencedDocTypes.push(target.value);
        } else {
            const idx = changed.referencedDocTypes.indexOf(target.value);
            if (idx > -1){
                changed.referencedDocTypes.splice(idx, 1);
            }
        }

        setCorpus(changed);
    }

    const saveCorpus = function() {
        let copy = {
            ...corpus
        };

        for(let i=0; i < copy.selectors.length; i++){
            delete copy.selectors[i]._id;
        }
        if (copy.id) {
            props.dispatch(updateOne('corpora', copy, (result) => alert('success')));
        } else {
            props.dispatch(createOne('corpora', copy, result => alert('success')));
        }
    }

    let selectors = corpus.selectors.map((s, i) => (
        <div key={s._id}>
            {s._id}
            <DocumentSelectorEditor selector={s} tenant={props.tenant} onChange={val => handleSelectorChanged(val, i)}
                onRemove={val => handleRemoveSelector(val, i)} idKeyOnly={true} />
            <hr />
        </div>));

    let refDocs = props.tenant.docTypes.map((dt, i) => (
        <p key={i}><label className="checkbox-inline" title={dt.name}><input type="checkbox" defaultChecked={corpus.referencedDocTypes.indexOf(dt.name) > -1} value={dt.name} onChange={handleRefDocTypeChanges} /> {dt.label}</label></p>
    ));

    return (
        <ContentScroller>
            <Container>
                <Row>
                    <Col><h5>Corpus:</h5></Col>
                </Row>
                <Row>
                    <Col>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col} controlId="name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" name="name" defaultValue={corpus.name} onBlur={handleCorpusChange} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="id">
                                    <p className="float-right">{corpus.id}</p>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="description">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea" rows={2} name="description" defaultValue={corpus.description} onBlur={handleCorpusChange} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="notes">
                                    <Form.Label>Internal Notes</Form.Label>
                                    <Form.Control as="textarea" rows={2} name="notes" defaultValue={corpus.notes} onBlur={handleCorpusChange} />
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col><h5>Referenced Document Types:</h5></Col>
                </Row>
                <Row>
                    <Col>
                        {refDocs}
                    </Col>
                </Row>
                <Row>
                    <Col><h5>Document Selectors:</h5></Col>
                </Row>
                <Row>
                    <Col>{selectors}</Col>
                </Row>
                <Row>
                    <Col>
                        <Button onClick={handleAddSelector}>Add New Selector</Button>
                        <Button onClick={saveCorpus}>Save Corpus</Button>
                    </Col>
                </Row>
            </Container>
        </ContentScroller>
    )
}

function mapStateToProps(state) {
    return {
        tenant: state.tenant.selectedTenant
    };
}

export default connect(mapStateToProps)(CorpusEditor);