import React, { useState } from 'react'
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import {ajaxWatched, apiRequest} from "../../shared/services";
import Row from "react-bootstrap/Row";
import Col from 'react-bootstrap/Col'
import ContentScroller from "../../shared/components/ContentScroller";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Modal from "react-bootstrap/Modal";

function searchModelDef(modelDefId, title, content, aggNames, callback) {
    let sels = aggNames.map(n => ({ aggName: n }));
    let body = JSON.stringify({
        title: title,
        content: content,
        selectors: sels
    });
    let res = `/model-defs/${modelDefId}?do=query`;
    let result = {};
    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, res, {
                method: 'POST',
                body: body
            })
                .then(response => {
                    result['totalItems'] = parseInt(response.headers.get('Total-Count'));
                    return response.json();
                })
                .then(json => {
                    result['items'] = json.result.items
                    callback(result);
                })
        ))
    }
}

function searchByTerms(modelDefId, terms, include, aggNames, callback) {
    let res = `/model-defs/${modelDefId}?do=with`;
    let result = {};
    let sels = aggNames.map(n => ({ aggName: n }));
    let body = JSON.stringify({
      include: include,
      terms: terms,
      selectors: sels
    });

    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, res, {
                method: 'POST',
                body: body
            })
                .then(response => {
                    result['totalItems'] = parseInt(response.headers.get('Total-Count'));
                    return response.json();
                })
                .then(json => {
                    result['items'] = json.result.items
                    callback(result);
                })
        ))
    }
}

function searchByIndexLoc(modelDefId, indexLocation, aggNames, callback) {
    let res = `/model-defs/${modelDefId}?do=like`;
    let result = {};
    let body = JSON.stringify({
        loc: indexLocation,
        selectors: aggNames.map(a => ({aggName: a}))
    });

    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, res, {
                method: 'POST',
                body: body
            })
                .then(response => {
                    result['totalItems'] = parseInt(response.headers.get('Total-Count'));
                    return response.json();
                })
                .then(json => {
                    result['items'] = json.result.items
                    callback(result);
                })
        ))
    }
}

const SearchControls = (props) => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [selectedAggs, setSelectedAggs] = useState([]);
    const [include, setInclude] = useState('all');
    const [searchMode, setSearchMode] = useState('term-search');

    const handleAggCheckChanged = ({target}) => {
        let newArgs = selectedAggs.filter(v => v !== target.value);
        if (target.checked) {
            newArgs.push(target.value);
        }
        setSelectedAggs(newArgs);
        props.onSelectedAggsChanged(newArgs);
    }

    let md = props.modelDef;
    let availableAggs = md.pipelineConfig.settings.aggregators.selectors.reduce((aggs, sel) => { aggs.push(sel.alias); return aggs;}, []).sort();

    let aggChecks = availableAggs.map((a, i) =>
        <Form.Check custom inline
            label={a}
            type="checkbox"
            defaultValue={a}
            defaultChecked={selectedAggs.indexOf(a) > -1}
            key={a} id={`selectedAgg-${i}`}
            onChange={e => handleAggCheckChanged(e)} />
    );

    let performSearch = () => {
        if (searchMode === 'content-search') {
            props.onContentSearch(title, content, selectedAggs);
        } else {
            props.onTermSearch(props.terms, include, selectedAggs);
        }
    }

    return (
        <Form>
            <Tabs activeKey={searchMode} onSelect={k => setSearchMode(k)}>
                <Tab eventKey="content-search" title="By Content">
                    <Form.Group as={Row} controlId="title">
                        <Form.Control type="text" placeholder="title" defaultValue={title} onChange={e => setTitle(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Row} controlId="content">
                        <Form.Control as="textarea" rows="3" placeholder="content" defaultValue={content} onChange={e => setContent(e.target.value)} />
                    </Form.Group>
                </Tab>
                <Tab eventKey="term-search" title="By Terms">
                    <Row>
                    <Form.Group as={Col} controlId="terms">
                        <Form.Control as="textarea" rows="5" placeholder="terms" value={props.terms.join("\n")} onChange={e => props.onTermsChanged(e.target.value.split("\n"))} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="include">
                        <Form.Label>Include</Form.Label>
                        <Form.Check type="radio" name="include" id="include-all" value="all" label="all" onChange={e => setInclude(e.target.value)} checked={include === "all"} />
                        <Form.Check type="radio" name="include" id="include-any" value="any" label="any" onChange={e => setInclude(e.target.value)} checked={include === "any"} />
                    </Form.Group>
                    </Row>
                </Tab>
            </Tabs>

            <Form.Group controlId="selectedAggs">
                {aggChecks}
            </Form.Group>
            <Form.Group controlId="buttons">
                <Form.Control type="button" defaultValue="Search" onClick={performSearch}></Form.Control>
            </Form.Group>
        </Form>
    )
}

const MetaDetails = props => {
    let meta = Object.entries(props.value.meta).sort((a, b) => a[0] - b[0]);
    meta.unshift(['sourceId', props.value.sourceId]);
    let rows = meta.map((kvp, i) => <tr key={kvp[0]}><td>{kvp[0]}: </td><td>{kvp[1] === null ? '' : kvp[1].toString()}</td></tr>);

    return <table>{rows}</table>
}

const QueryResultItem = props => {
    let v = props.value;
    let row = null;
    let topMatches = v.topMatches
        .map(item => <Button key={item[0]} className="m-1" variant="outline-success" onClick={e => props.onToggleTerm(item[0])}>{item[0]} ({item[1].toLocaleString(undefined, {maximumFractionDigits: 3})})</Button>);
    let topWords = v.topWords
        .map(item => <Button key={item[0]} className="m-1" variant="outline-secondary" onClick={e => props.onToggleTerm(item[0])}>{item[0]} ({item[1].toLocaleString(undefined, {maximumFractionDigits: 3})})</Button>);

    if (v.doc === null) {
        row = (
            <Row>
                <Col>
                    <Row>
                        <Col sm="8"><h6>{v.simScore.toLocaleString(undefined, {maximumFractionDigits: 3})} - {v.aggName}</h6></Col>
                        <Col sm="4"><Button variant="link" onClick={() => props.onDocsLikeThis(v.indexLocation, props.aggNames)}>{v.docKey}</Button></Col>
                    </Row>
                    <Row><Col>{topMatches}{topWords}</Col></Row>
                </Col>
            </Row>
        )

    }
    else {
        row = (
            <Row>
                <Col>
                    <Row>
                        <Col sm="8"><h6>{v.simScore.toLocaleString(undefined, {maximumFractionDigits: 3})} - {v.aggName}</h6></Col>
                        <Col sm="4"><Button variant="link" onClick={() => props.onDocsLikeThis(v.indexLocation, props.aggNames)}>{v.docKey}</Button></Col>
                    </Row>
                    <Row>
                        <Col sm="8">
                            <Row><Col>{v.doc.title}</Col></Row>
                            <Row><Col>{v.doc.content}</Col></Row>
                        </Col>
                        <Col sm="4"><MetaDetails value={v.doc}/></Col>
                    </Row>
                    <Row><Col>{topMatches}{topWords}</Col></Row>
                </Col>
            </Row>
        )
    }

    return <>{row}<hr /></>;
}

const QueryResultsList = props => {
    if (props.value === null){
        return <></>
    }

    let items = props.value['items'].map(item => <QueryResultItem key={item.indexLocation} value={item} onToggleTerm={props.onToggleTerm} onDocsLikeThis={props.onDocsLikeThis} />)

    return (
        <>
            <div>{props.value['totalItems'].toLocaleString()} results</div>
            <Container>
                {items}
            </Container>
        </>
    )
}

class SimilarityModelExplorer extends React.Component {
    constructor() {
        super();
        this.state = {
            results: null,
            isSearching: false,
            terms: [],
            likeDocResults: null,
            aggNames: []
        }
    }

    watchAggNames(aggs) {
        this.setState({
            aggNames: aggs
        });
    }

    showResults(results) {
        this.setState({
            results: results,
            isSearching: false
        });
    }

    contentSearch(title, content, selectedAggs) {
        this.setState({isSearching: true});
        this.props.dispatch(searchModelDef(this.props.modelDef.id, title, content, selectedAggs, r => this.showResults(r)));
    }

    termSearch(terms, include, selectedAggs) {
        this.setState({isSearching: true});
        this.props.dispatch(searchByTerms(this.props.modelDef.id, terms, include, selectedAggs, r => this.showResults(r)));
    }

    showLikeDocs(results) {
        this.setState({
            likeDocResults: results,
            isSearching: false
        });
    }

    searchDocsLikeThis(indexLocation) {
        this.setState({isSearching: true, likeDocResults: null});
        this.props.dispatch(searchByIndexLoc(this.props.modelDef.id, indexLocation, this.state.aggNames, r => this.showLikeDocs(r)));
    }

    toggleTerm(term) {
        let terms = [...this.state.terms];
        let idx = this.state.terms.indexOf(term);
        if (idx === -1){
            terms.push(term);
        } else
        {
            terms.splice(idx, 1);
        }
        this.setState({terms: terms});
    }

    render(){
        if (this.props.modelDef === null) {
            return <Spinner animation="border" />
        }

        return (
            <>
                <div>
                    <Container>
                        <div>Model Search: {this.props.modelDef.id}</div>
                        <SearchControls modelDef={this.props.modelDef} onSelectedAggsChanged={aggs => this.watchAggNames(aggs)} onContentSearch={(a, b, c) => this.contentSearch(a, b, c)} onTermSearch={(terms, include, aggs) => this.termSearch(terms, include, aggs)} terms={this.state.terms} onTermsChanged={t => this.setState({terms: t})} />
                    </Container>
                </div>
                <ContentScroller>
                    <Container>
                        <Row>
                        {this.state.isSearching ? <Row><Spinner animation="border" /></Row> : <QueryResultsList value={this.state.results} onToggleTerm={t => this.toggleTerm(t)} onDocsLikeThis={idx => this.searchDocsLikeThis(idx)} />}
                        </Row>
                <Modal show={this.state.likeDocResults} onHide={() => this.setState({likeDocResults: null})} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>Similar Documents</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <QueryResultsList value={this.state.likeDocResults} onToggleTerm={t => this.toggleTerm(t)} onDocsLikeThis={idx => this.searchDocsLikeThis(idx)} />
                    </Modal.Body>
                    <Modal.Footer>
                        TODO: Paging...
                    </Modal.Footer>
                </Modal>
                    </Container>
                </ContentScroller>
            </>
        )
    }
}

function mapStateToProps(state){
    return {};
}

export default connect(mapStateToProps)(SimilarityModelExplorer);