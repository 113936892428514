import { AJAX_WATCH_REQUEST, AJAX_UNWATCH_REQUEST } from '../actions/ajax'

const initialState = {
    activeRequests: [], // array of object tokens representing a request; not actually xhr objects
                        // later, these may contain state (such as a label describing the request)
    isFetching:false, // true if any requests are currently active, otherwise false
    requestCount: 0 // the number of active requests
};

export default function ajaxReducer(ajax=initialState, action) {
    let activeRequests = null;
    switch (action.type)
    {
        case AJAX_WATCH_REQUEST:
            activeRequests = ajax.activeRequests.concat(action.token);
            return {
                ...ajax,
                activeRequests: activeRequests,
                isFetching: true,
                requestCount: activeRequests.length
            };
        case AJAX_UNWATCH_REQUEST:
            activeRequests = ajax.activeRequests.filter(o => o !== action.token);
            return {
                ...ajax,
                activeRequests: activeRequests,
                isFetching: activeRequests.length > 0,
                requestCount: activeRequests.length
            };
        default:
            break;
    }
    return ajax;
}