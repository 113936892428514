import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';


import Home from './components/home/Home';
import TenantEditor from "./components/tenant/TenantEditor";
import LogIn from "./components/home/LogIn";
import AnalysisOutputList from "./components/analysis/AnalysisOutputList";
import AnalysisOutputViewer from "./components/analysis/AnalysisOutputViewer";
import DocsDashboard from "./components/tenant/DocsDashboard";
import ModelDefEditor from "./components/tenant/ModelDefEditor";
import ModelSearch from "./components/tenant/ModelSearch";
import ListCorpora from './components/tenant/ListCorpora';
import CorpusEditor from "./components/tenant/CorpusEditor";
import ListModelDefs from "./components/tenant/ListModelDefs";
import Scratchpad from "./components/tenant/Scratchpad";
import LogDetails from "./components/admin/LogDetails";


const AuthenticatedRoute = ({component: Component, authed, ...rest}) => {
        return (
            <Route
                {...rest}
                render={(props) => authed === true
                        ? <Component {...props} />
                        : <Redirect to={{pathname:'/helm/login', state: {from: props.location}}} />}
            />
        )
}

const AppRoutes = (props) => {
        let authed = props.user.auth_token ? true : false;
        let role = authed ? props.user.caps.role : '';

        return (<Switch>
                <Route path='/helm/t/:tenantId/login' component={LogIn} exact />
                <Route path='/helm/login' component={LogIn} exact />

                <Route path='/helm/foo' component={Scratchpad} exact />

                <AuthenticatedRoute authed={authed} path='/helm/t/:tenantId' component={Home} exact/>
                <AuthenticatedRoute authed={authed} path='/helm/t/:tenantId/docs' component={DocsDashboard} exact />
                <AuthenticatedRoute authed={authed} path="/helm/t/:tenantId/edit" component={TenantEditor} exact />
                <AuthenticatedRoute authed={authed} path="/helm/t/:tenantId/corpora" component={ListCorpora} exact />
                <AuthenticatedRoute authed={authed} path="/helm/t/:tenantId/corpus/:corpusId" component={CorpusEditor} exact />
                <AuthenticatedRoute authed={authed} path="/helm/t/:tenantId/corpus/" component={CorpusEditor} exact />
                <AuthenticatedRoute authed={authed} path="/helm/t/:tenantId/model-defs/new" component={ModelDefEditor} exact />
                <AuthenticatedRoute authed={authed} path="/helm/t/:tenantId/model-defs/:modelDefId" component={ModelDefEditor} exact />
                <AuthenticatedRoute authed={authed} path="/helm/t/:tenantId/model-defs" component={ListModelDefs} exact />
                <AuthenticatedRoute authed={authed} path="/helm/t/:tenantId/models/:modelDefId/search" component={ModelSearch} exact />
                <AuthenticatedRoute authed={authed} path='/helm/t/:tenantId/analysis-outputs' component={AnalysisOutputList} exact/>
                <AuthenticatedRoute authed={authed} path='/helm/t/:tenantId/analysis-outputs/:id' component={AnalysisOutputViewer} exact/>

                <AuthenticatedRoute authed={role === 'admin'} path='/helm/t/:tenantId/admin/log-details' component={LogDetails} exact />

                <AuthenticatedRoute authed={authed} path='/helm' component={Home} exact/>
        </Switch>);
}

function mapStateToProps(state) {
        return {
                user: state.user
        };
}

export default connect(mapStateToProps)(AppRoutes);