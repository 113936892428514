import { ajaxWatchRequest, ajaxUnwatchRequest } from './actions/ajax';
//import mapDispatchToProps from "react-redux/lib/connect/mapDispatchToProps";
import {taskStarted} from "./actions/task";
import {userAuthenticated} from "./actions/user";
//import { uxRedirect } from "./actions/ux";

export function ajaxWatched(requestPromise) {
    var requestToken = {};
    return (dispatch, getState) => {
        dispatch(ajaxWatchRequest(requestToken));
        // TODO: Replace these alerts with UX notifications
        return requestPromise
            .catch(err => {
                if (err instanceof ApiError){
                    switch(err.response.status){
                        case 403:
                        case 422:
                            err.response.json().then(json => {
                                alert(json.error);
                            })
                            break;
                        default:
                            alert(`An API error was not handled for ${err.opts.method} ${err.url}.`);
                            break;
                    }
                }
                else {
                    console.log(err);
                    // This is only encountered for network errors; notify user of offline status
                    alert('You appear to have lost your connection to the internet or the server is unavailable.');
                }
            })
            .finally(() => dispatch(ajaxUnwatchRequest(requestToken)));
    }
}

export class ApiError extends Error {
    constructor(url, opts, response){
        super(`Failed requesting ${url}`)
        this.url = url;
        this.opts = opts;
        this.response = response;
    }
}



export function apiRequest(dispatch, getState, resource, init={}, tenantId=undefined){
    let headers = init.headers === undefined ? { 'Content-Type': 'application/json' } : init.headers;

    if (headers.Authorization === undefined) {
        try {
            let auth_token = getState().user.auth_token;
            headers['Authorization'] = 'Basic ' + btoa('ISSUED_TOKEN:' + auth_token);
        } catch (Exception) {
        }
    }

    let opts = {...{
        'headers': headers,
        'credentials': 'include',
        'method': 'GET'
    }, ...init};

    if (tenantId === undefined){
        tenantId = getState().tenant.selectedTenant.id;
    }

    var serviceUrl = '';
    if (process.env.NODE_ENV === 'development') {
        serviceUrl = '//localhost:5000';
    }

    let url = '';
    if (resource.toLowerCase().startsWith('http://') || resource.toLowerCase().startsWith('https://')){
        url = resource;
    } else if (resource.startsWith('/api/v1/t/')) {
        url = serviceUrl + resource
    } else {
        url = serviceUrl + '/api/v1/t/' + encodeURIComponent(tenantId) + resource;
    }

    let { lastAuth, credentials } = getState().user;

    function reauthenticateUser(userName, password, tenantId) {
        return fetch(serviceUrl + '/api/v1/t/' + encodeURIComponent(tenantId) + '/auth-tokens/' + encodeURIComponent(userName),
            {
                method: 'GET',
                credentials: 'include',
                headers: {Authorization: 'Basic ' + btoa(userName + ':' + password)}
            })
            .then(response => response.json())
            .then(json => {
                    dispatch(userAuthenticated(json.result, userName, password, tenantId));
                    return json;
                }
            );
    }

    const doFetch = function() {
        return fetch(url, opts)
            .then(response => {
                if (!response.ok){
                    if (response.status === 403) {
                        alert('Access denied. Your auth token may have expired. Try logging in again.');
                    }
                    throw new ApiError(url, opts, response);
                }
                return response;
            });
    }

    if (Date.now() - lastAuth > 270000) {  // refresh auth token every 4.5 minutes (server expires 5 mins)
        return reauthenticateUser(credentials.userName, credentials.password, credentials.tenantId)
            .then(json => {
                headers['Authorization'] = 'Basic ' + btoa('ISSUED_TOKEN:' + json.result.token)
            })
            .then(doFetch);
    }
    else {
        return doFetch();
    }
}

export function findOne(resourceName, resourceId, callback) {
    let resource = '/' + encodeURIComponent(resourceName) + '/' + encodeURIComponent(resourceId);
    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, resource)
                .then(response => response.json())
                .then(json => callback(json.result))
        ))
    }
}

export function updateOne(resourceName, entity, callback) {
    let body = JSON.stringify(entity);
    let id = entity.id;
    let resource = '/' + encodeURIComponent(resourceName) + '/' + encodeURIComponent(id);
    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, resource, {
                method: 'PUT',
                body: body
            })
            .then(response => callback(response))
        ));
    }
}

export function createOne(resourceName, entity, callback) {
    let body = JSON.stringify(entity);
    let resource = '/' + encodeURIComponent(resourceName);
    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, resource, {
                method: 'POST',
                body: body
            })
            .then(response => response.json())
            .then(json => callback(json.value))
        ))
    }
}

export function findMany(resourceName, callback) {
    let itemCount = undefined;
    let resource = '/' + encodeURIComponent(resourceName);
    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, resource)
                .then(response => {
                    itemCount = parseInt(response.headers.get('Total-Count'));
                    return response.json()
                })
                .then(json => callback(json.result.items, itemCount))
        ));
    }
}

export function getPagedRequest(resource, callback){
    let headers = undefined;
    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, resource)
                .then(response => {headers = response.headers; return response.json()})
                .then(json => callback({
                    totalCount: parseInt(headers.get('Total-Count')),
                    links: parseLinks(headers),
                    items: json.result.items
                }))
        ))
    }
}

export function beginModelTask(tenantId, modelDefId, taskName, onFinishedCallback) {
    // TODO: remove this function once KeyTermsPerAgg task has been
    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, `/tasks/${taskName}`, {
                method: 'POST',
                body: JSON.stringify({model_def_id: modelDefId})})
                .then(response => response.json())
                .then(json => dispatch(taskStarted(json.value, onFinishedCallback)))

        ))
    }
}

export function runPipeline(tenantId, modelDefId, config, callback) {

    return (dispatch, getState) => {
        dispatch(ajaxWatched((
            apiRequest(dispatch, getState, `/model-defs/${modelDefId}?do=runpipeline`, {
                method: 'POST',
                body: JSON.stringify(config)
            })
                .then(response => response.json())
                .then(json => dispatch(taskStarted(json.value, callback)))
        )))
    }
}

export function deleteModelDef(tenantId, modelDefId, callback) {
        return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, `/model-defs/${modelDefId}`,{
                method: 'DELETE'
            })
            .then(response => callback())
        ))
    }
}

export function parseLinks(headers){
    let link = headers.get('Link');
    if (link == null || link.length === 0){
        return {};
    }

    let links = link.split(',').reduce((d, i) => {
        let [l, rel] = i.split(';');
        d[rel.split('=')[1]] = l.substring(1, l.length-1);
        return d;
    }, {});

    return links;
}