export const AJAX_WATCH_REQUEST = 'AJAX_WATCH_REQUEST';
export const AJAX_UNWATCH_REQUEST = 'AJAX_UNWATCH_REQUEST';

export function ajaxWatchRequest(token) {
    return {
        type: AJAX_WATCH_REQUEST,
        token: token
    };
}

export function ajaxUnwatchRequest(token) {
    return {
        type: AJAX_UNWATCH_REQUEST,
        token: token
    };
}