import React from 'react';
import { connect } from 'react-redux';
import './layout.css';
import TaskMonitor from "../../components/TaskMonitor";

const Footer = props => {
    let { requests } = props;
    return (
        <div className='layout-footer'>{requests} requests, <TaskMonitor /></div>
    );
}

function mapStateToProps(state) {
    return {
        requests: state.ajax.requestCount
    }
}

export default connect(mapStateToProps)(Footer);