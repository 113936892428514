import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import { Button } from "react-bootstrap";
import {ajaxWatched, apiRequest} from "../../shared/services";
import Row from "react-bootstrap/Row";
import Col from 'react-bootstrap/Col'
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ContentScroller from "../../shared/components/ContentScroller";
import { PipelineConfigEditor } from "./ModelDefEditor";
import AnalysisOutputTable from "../analysis/AnalysisOutputTable";


function getSearchConfigs(modelDefId, callback) {
    let res = `/models/${modelDefId}`;
    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, res, {
                method: 'GET'
            })
                .then(response => {
                    return response.json();
                })
                .then(json => {
                    callback(json.result.searchConfigs);
                })
        ))
    }
}

function executeSearch(modelDefId, pipelineConfig, callback) {
    let res = `/models/${modelDefId}`;
    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, res, {
                method: 'POST',
                body: JSON.stringify(pipelineConfig)
            })
                .then(response => {
                    return response.json();
                })
                .then(json => {
                    callback(json)
                })
        ))
    }
}

function refreshSearchConfig(modelDefId, searchPipelineTypeUUID, changeAction, currentSettings, currentSettingsDefinition, callback) {
    let res = `/models/${modelDefId}/search-configs/${searchPipelineTypeUUID}`;
    let payload = {
        change_action: changeAction,
        current_settings: currentSettings,
        current_settings_definition: currentSettingsDefinition
    };

    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, res, {
                method: 'PUT',
                body: JSON.stringify(payload)
            })
        ))
            .then(response => {
                return response.json();
            })
            .then(json => {
                callback(json.result);
            })
    }
}

function formatValue(val) {
    let result = val.toString();
    
    let normalized = result.toLowerCase().trim();
    if (normalized.startsWith('http://') || normalized.startsWith('https://')){
        result = <a href={result.trim()} target="_blank" rel="noreferrer" nofollow>{result}</a>
    }

    return result;
}

const SearchResultItem = props => {
    let v = props.value;
    let row = null;
    /*let topMatches = v.topMatches
        .map(item => <Button key={item[0]} className="m-1" variant="outline-success" onClick={e => props.onToggleTerm(item[0])}>{item[0]} ({item[1].toLocaleString(undefined, {maximumFractionDigits: 3})})</Button>);
    let topWords = v.topWords
        .map(item => <Button key={item[0]} className="m-1" variant="outline-secondary" onClick={e => props.onToggleTerm(item[0])}>{item[0]} ({item[1].toLocaleString(undefined, {maximumFractionDigits: 3})})</Button>);
    */

    let details = v.details.map((kvp, i) => <tr key={kvp[0]}><td>{kvp[0]}: </td><td>{kvp[1] === null ? '' : formatValue(kvp[1])}</td></tr>);

    details = <table>{details}</table>;

    let stats = v.stats.map((kvp, i) => <Row><Col sm={2}>{kvp[0]}: </Col><Col sm={10}>{kvp[1] === null ? '' : formatValue(kvp[1])}</Col></Row>)

    row = (
        <Row>
            <Col sm="7">
                <Row><Col>{v.title}</Col></Row>
                <Row><Col>{v.description}</Col></Row>
            </Col>
            <Col sm="5"><ContentScroller style={{maxHeight: '35em', fontSize: '70%'}}>{details}</ContentScroller></Col>
        </Row>

    )

    return <>{row}{stats}<hr /></>;
}

const SearchResultsList = props => {
    if (props.value === null){
        return <></>
    }

    let items = props.items.map(item => <SearchResultItem key={item.id} value={item} />)

    return (
        <>
            <Container>
                {items}
            </Container>
        </>
    )
}

const DefaultModelExplorer = props => {
    const [searchConfigs, setSearchConfigs] = useState(undefined);
    const [selectedTab, setSelectedTab] = useState(0);
    const [searchResults, setSearchResults] = useState(undefined);
    const [isSearching, setIsSearching] = useState(false);
    const [isLoadingConfig, setIsLoadingConfig] = useState(false);

    const afterGetSearchConfigs = val => {
        setIsLoadingConfig(false);
        setSearchConfigs(val);
    }

    const initSearchConfigs = () => {
        if (searchConfigs === undefined) {
            setIsLoadingConfig(true);
            props.dispatch(getSearchConfigs(props.modelDef.id, afterGetSearchConfigs));
        }
    }

    useEffect(initSearchConfigs, [props, searchConfigs]);

    if (searchConfigs === undefined) {
        return <Spinner animation="border" />
    }

    const onTabSelected = tabKey => {
        setSelectedTab(tabKey);
        setSearchResults(undefined);
    }

    const handleRefreshSearchConfig = searchConfig => {
        let newConfigs = [...searchConfigs];
        newConfigs[selectedTab] = searchConfig
        setSearchConfigs(newConfigs);
        setIsLoadingConfig(false);
    }

    const handleConfigChange = (val, args) => {
        let changed = [
            ...searchConfigs
        ]
        console.log('DefaultModelExplorer: config changed', val, args)

        let changedCfg = {...changed[selectedTab]}
        changedCfg.defaultSettings = {...changedCfg.defaultSettings, ...val};
        changed[selectedTab] = changedCfg;
        setSearchConfigs(changed);

        if (args.settingDef.triggers_change_action !== null) {
            setIsLoadingConfig(true);
            props.dispatch(refreshSearchConfig(
                props.modelDef.id,
                changedCfg.uuid,
                args.settingDef.triggers_change_action,
                changedCfg.defaultSettings,
                changedCfg.settingsDefinition,
                handleRefreshSearchConfig));
        }
    }

    const onAfterExecuteSearch = results => {
        setSearchResults(results);
        setIsSearching(false);
    }

    const handleSearchClick = e => {
        let modelDefId = props.modelDef.id;
        let cfg = searchConfigs[selectedTab];

        let pipelineConfig = {
            pipelineType: cfg.uuid,
            settings: cfg.defaultSettings
        }
        setIsSearching(true);
        props.dispatch(executeSearch(modelDefId, pipelineConfig, onAfterExecuteSearch));
    }

    let tabs = searchConfigs.map((cfg, idx) => (<Tab key={idx} eventKey={idx} title={cfg.name}>
        <div style={{padding: '1em 0 2em'}}><em>{cfg.description}</em></div>
        <PipelineConfigEditor
            settingDef={cfg.settingsDefinition} scopeSettings={cfg.defaultSettings} onChange={handleConfigChange}/>
    </Tab>));

    let resultsView = null;

    if (isSearching){
        resultsView = <Row style={{paddingTop: '2em', height: '100em'}}><Spinner animation="border" /></Row>;
    }
    else {
        if (searchResults !== undefined) {
            let result = searchResults.result.result;
            if (result.resultType === 'tabular') {
                resultsView = (
                    <Row style={{paddingTop: '2em'}}>
                        <AnalysisOutputTable results={result.value}/>
                    </Row>
                )
            } else if (result.resultType === 'object-list') {
                if (result.itemType === 'SearchResultEntity') {
                    resultsView = (
                        <Row style={{paddingTop: '2em'}}>
                            <SearchResultsList items={result.value}/>
                        </Row>
                    )
                }
            }
        }
    }

    if (isLoadingConfig){
        return (
            <ContentScroller>
                <Container>
                    <Row>
                        <Spinner animation="border" />
                    </Row>
                </Container>
            </ContentScroller>
        )
    }

    return (
        <ContentScroller>
            <Container>
                <Row><div style={{paddingBottom: '3em'}}>Searching active model: {props.modelDef.id}</div></Row>
                <Row>
                    <Col>
                        <Tabs activeKey={selectedTab} onSelect={onTabSelected}>
                            {tabs}
                        </Tabs>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button onClick={handleSearchClick} disabled={isSearching} >Search</Button>
                    </Col>
                </Row>
                {resultsView}
            </Container>
        </ContentScroller>
    )
}

function mapStateToProps(state){
    return {};
}

export default connect(mapStateToProps)(DefaultModelExplorer);