import React, {useState, useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import DataGrid, { TextEditor, SelectColumn } from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';

// https://github.com/adazzle/react-data-grid/blob/canary/stories/demos/AllFeatures.tsx

const BoolFormatter = (record) => {
    return record.row[record.column.key] ? 'Yes' : 'No';
}

const CheckboxEditor = ({row, column, onRowChange, onClose}) => {
    function handleOnChange(event) {
        var newRow = {...row, [column.key]: event.target.checked };
        onRowChange(newRow);
    }

    function handleOnClose() {
        onClose(true);
    }
    return (
        <input type="checkbox"
               ref={(input) => {if (input != null) {input.focus(); input.select();}}}
               defaultChecked={row[column.key]}
               onChange={handleOnChange}
               onBlur={handleOnClose}
        />
    );
}

const IndexedPropSelectorEditor = ({row, column, onRowChange, onClose}) => {
    function handleOnChange(event) {
        var newRow = {...row};
        newRow[column.key] = event.target.value;
        onRowChange(newRow);
    }

    function handleOnClose() {
        onClose(true);
    }

    let curVal = row[column.key];

    return (
        <select onChange={handleOnChange} onBlur={handleOnClose}>
            <option value="" selected={curVal === ''}>Not Indexed</option>
            <option value="text/plain" selected={curVal === 'text/plain'}>text/plain</option>
            <option value="text/html" selected={curVal === 'text/html'}>text/html</option>
        </select>
    );
}



const DocTypeEditor = ({docType, onChange, onRemove}) => {
    const [selectedRows, setSelectedRows] = useState(() => new Set());
    const [rows, setRows] = useState(docType.properties.rows.map(r => ({
        property: r[0],
        type: r[1],
        format: r[2],
        required: r[3],
        allownull: r[4],
        islist: r[5],
        indextype: r[6]
    })));


    useEffect(() => {
        let copy = rows.map(r => [r['property'], r['type'], r['format'], r['required'], r['allownull'], r['islist'], r['indextype']]);
        let upd = {...docType};
        upd.properties.rows = copy;
        onChange(upd);
    }, [rows]); // Refactor or ignore warning for docType & onChange dependencies

    const cols = ['Property', 'Type', 'Format', 'Required', 'AllowNull', 'IsList', 'IndexType'].map(c => ({
        key: c.toLowerCase(),
        name: c,
        editor: TextEditor,
    }));
    cols[3]['formatter'] = BoolFormatter;
    cols[3]['editor'] = CheckboxEditor;

    cols[4]['formatter'] = BoolFormatter;
    cols[4]['editor'] = CheckboxEditor;

    cols[5]['formatter'] = BoolFormatter;
    cols[5]['editor'] = CheckboxEditor;

    cols[6]['editor'] = IndexedPropSelectorEditor;

    cols.unshift(SelectColumn);

    const rowKeyGetter = (row) => {
        return row.property;
    }

    const removeSelectedProps = e => {
        let copy = rows.filter(v => !selectedRows.has(v['property']));
        setRows(copy);
    }

    const bubbleChanges = (updated) => {
        let upd = {
            ...docType,
            ...updated
        };
        onChange(upd);
    }

    const handleChanges = ({target}) => {
        let upd = {};
        switch (target.name) {
            case 'name':
            case 'label':
            case 'description':
                upd[target.name] = target.value;
                bubbleChanges(upd);
                break
            case 'isTitleIndexed':
            case 'isContentIndexed':
                upd[target.name] = target.checked;
                bubbleChanges(upd);
                break;
            case 'addtl_props':
                bubbleChanges({
                    addtlProps: target.checked
                });
                break;
            default:
                break;
        }
    }

    const addNewProp = () => {
        const newPropCnt = rows.reduce((total, curVal) => {
            if (curVal['property'].startsWith('new_property')){
                total += 1;
            }
            return total;
        }, 0);
        let copy = [...rows, {property: 'new_property_' + newPropCnt.toString(), type: 'string', format: null,
            required: false, allownull: true, islist: false, indextype: null}];

        setRows(copy);
    }

    const removeDocType = () => {
        onRemove(docType);
    }

    return (
        <>
            <Form.Row>
                <Form.Group as={Col} controlId="key">
                    <Form.Label>Key</Form.Label>
                    <Form.Control type="text" defaultValue={docType.name} name="name" onBlur={handleChanges} />
                </Form.Group>
                <Form.Group as={Col} controlId="label">
                    <Form.Label>Label</Form.Label>
                    <Form.Control type="text" defaultValue={docType.label} name="label" onBlur={handleChanges} />
                </Form.Group>
            </Form.Row>
            <Form.Group controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" rows="2" defaultValue={docType.description} name="description" onBlur={handleChanges} />
            </Form.Group>
            <Form.Group>
                <Form.Label>Indexing</Form.Label>
                <Form.Group as={Col}>
                    <Form.Check type="checkbox" defaultChecked={docType.isTitleIndexed} name="isTitleIndexed" label="Is Title Indexed?" onBlur={handleChanges} />
                    <Form.Check type="checkbox" defaultChecked={docType.isContentIndexed} name="isContentIndexed" label="Is Content Indexed?" onBlur={handleChanges} />
                </Form.Group>
            </Form.Group>
            <Form.Group controlId="properties">
                <Form.Label>Properties</Form.Label>
                <DataGrid
                    columns={cols}
                    rows={rows}
                    onRowsChange={setRows}
                    rowKeyGetter={rowKeyGetter}
                    selectedRows={selectedRows}
                    onSelectedRowsChange={setSelectedRows}
                />

            </Form.Group>
            <Form.Group as={Row} controlId="actions">
                <Col>
                <Form.Check type="checkbox" label="Allow addtl. properties" name="addtl_props" defaultChecked={docType.addtlProps} onChange={handleChanges} />
                </Col>
                <Col>
                    <Form.Control type="button" value="Add New Prop" onClick={addNewProp} />
                </Col>
                <Col>
                    <Form.Control type="button" value="Delete Selected Props" onClick={removeSelectedProps} />
                </Col>
                <Col>
                    <Form.Control type="button" value="Remove Doc Type" onClick={removeDocType} />
                </Col>
            </Form.Group>
        </>
    )
}

export default DocTypeEditor;
