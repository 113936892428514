import {ajaxWatched, apiRequest} from "../services";

export const TASK_STARTED = 'TASK_STARTED';
export const TASK_STATUS_REFRESHED = 'TASK_STATUS_REFRESHED';
export const TASK_FINISHED = 'TASK_FINISHED';


export function getTaskStatus(taskId, callback) {
    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, `/task-statuses/${taskId}`)
                .then(response => response.json())
                .then(json => callback(json.result))
        ));
    }
}

function monitorTaskStatus(taskId, onFinishedCallback) {
    return (dispatch, getState) => {
        let timerInfo = {
            awaitingResponse: false
        };

        const processStatus = (json) => {
            timerInfo.awaitingResponse = false;
            try {
                console.log(json.status, json.result.step_status, json.result.item_progress, json);
            } catch {
                console.log(json);
            }
            switch (json.status) {
                case "SUCCESS":
                case "FAILURE":
                    clearInterval(timerInfo.handle);
                    if (onFinishedCallback !== undefined) {
                        onFinishedCallback(json);
                    }
                    dispatch(taskFinished(json.task_id));
                    break;
                default:
                    break;
            }
        }

        const checkStatus = () => {
            if (timerInfo.awaitingResponse){
                return;
            }
            timerInfo.awaitingResponse = true;
            dispatch(getTaskStatus(taskId, processStatus))
        }

        timerInfo.handle = setInterval(checkStatus, 1000);
    }
}

export function taskFinished(taskId) {
    return {
        type: TASK_FINISHED,
        taskId: taskId
    };
}

export function taskStarted(taskId, onFinishedCallback) {
    return (dispatch, getState) => {
        dispatch({
            type: TASK_STARTED,
            taskId: taskId
        });
        dispatch(monitorTaskStatus(taskId, onFinishedCallback));
    }
}