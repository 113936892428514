import React, {useEffect, useState} from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { apiRequest, ajaxWatched } from "../../shared/services";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import ContentScroller from "../../shared/components/ContentScroller";
import { exportAnalysisResults } from "../../shared/actions/analysisOutputs";


function getAnalysisOutputs(callback) {
    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, '/analysis-outputs?fields=-results',{})
                .then(response => response.json())
                .then(json => callback(json.result.items))
        ))
    }
}

function deleteAnalysisOutput(id, setItemsCallback) {
    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, `/analysis-outputs/${id}`,{
                method: 'DELETE'
            })
                .then(response => dispatch(getAnalysisOutputs(setItemsCallback)))
        ))
    }
}

const WrappedGrid = props => {
    let { cols, children } = props;
    let rows = [];
    let row = undefined;
    for (let i=0; i < children.length; i++){
        if (i % cols === 0) {
            if (row !== undefined) {
                rows.push(<Row key={rows.length} className="my-4">{row}</Row>);
            }
            row = [];
        }
        row.push(<Col key={i} lg={12 / cols}>{children[i]}</Col>);
    }
    rows.push(<Row key={rows.length} className="my-2">{row}</Row>);

    return <>{rows}</>
}

const AnalysisOutputList = props => {
    const [selected, setSelected] = useState(null);
    const [items, setItems] = useState(null);

    useEffect(() => {
        if (items === null) {
            props.dispatch(getAnalysisOutputs(items => setItems(items)));
        }
    }, [items, props] );

    if (selected !== null){
        return <Redirect to={`./analysis-outputs/${selected}`} />
    }

    if (items != null) {
        let cards = items.map(i => <Card key={i.id}>
            <Card.Header as="h5">{i.analysis}</Card.Header>
            <Card.Body>
                <Card.Title>{new Date(i.runTimestampUtc).toString()}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Run by: {i.initiatedBy}</Card.Subtitle>
                <Card.Text>
                    {JSON.stringify(i.args)}
                </Card.Text>
                <ButtonToolbar>
                    <Button variant="primary" className="mx-1" onClick={e => setSelected(i.id)}>View Results</Button>
                    <Button variant="outline-secondary" className="mx-1"
                            onClick={e => props.dispatch(exportAnalysisResults(i.id))}>Export</Button>
                    <Button variant="critical"
                            onClick={e => props.dispatch(deleteAnalysisOutput(i.id, setItems))}>Delete</Button>
                </ButtonToolbar>
            </Card.Body>
        </Card>);

        return <ContentScroller><Container><WrappedGrid cols={3}>{cards}</WrappedGrid></Container></ContentScroller>
    }

    return <ContentScroller><Container><Row><Col><Spinner animation="border"/></Col></Row></Container></ContentScroller>;
};

function mapStateToProps(state) {
    return {
        tenant: state.tenant.selectedTenant
    };
}

export default connect(mapStateToProps)(AnalysisOutputList);