import React from 'react';
import { connect } from 'react-redux';
import './App.css';
import DefaultLayout from '../shared/layouts/default/Layout';
import AnonymousLayout from '../shared/layouts/anoynmous/Layout';

function App(props) {
    let { children } = props;

    let layout;
    if (props.user.auth_token){
        layout = <DefaultLayout>{children}</DefaultLayout>;
    } else
    {
        layout = <AnonymousLayout>{children}</AnonymousLayout>
    }

    return (
        <div className="helm-app">
            {layout}
        </div>
    );
}

function mapStateToProps(state) {
    return {user: state.user};
}

export default connect(mapStateToProps)(App);
