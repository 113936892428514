export const USER_AUTHENTICATED = 'USER_AUTHENTICATED';

export function userAuthenticated(json, userName, password, tenantId) {
    return {
        type: USER_AUTHENTICATED,
        auth_token: json.token,
        caps: json.capabilities,
        credentials: {
            userName: userName,
            password: password,
            tenantId: tenantId
        }
    }
};