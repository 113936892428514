import React, {useEffect, useState} from 'react';
import DataGrid from 'react-data-grid';
import { CSVLink } from 'react-csv';

function AnalysisOutputRowsAsDicts({rows, columns}) {
    return rows.map(r => {
        let row = {};
        for (let c=0; c < columns.length; c++){
            row[columns[c]] = r[c];
        }
        return row;
    })
}

const AnalysisOutputTable = ({results}) => {
    const [[sortColumn, sortDirection], setSort] = useState([results.columns[0], 'NONE']);
    const [columns, setColumns] = useState(results.columns.map(c => ({
        key: c,
        name: c,
        sortable: true,
        resizable: true
    })));
    const [rows, setRows] = useState(AnalysisOutputRowsAsDicts(results));

    useEffect(() => {
        setColumns(results.columns.map(c => ({
            key: c,
            name: c,
            sortable: true,
            resizable: true
        })));
        setRows(AnalysisOutputRowsAsDicts(results));
    }, [results]);

    let csvData = [results.columns.slice()];
    csvData = csvData.concat([...results.rows]);

    const handleSort = (sortColumn, sortDirection) => {
        const comparer = (a, b) => {
            if (sortDirection === 'ASC'){
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } else if (sortDirection === 'DESC') {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
        };

        if (sortDirection !== 'NONE'){
            setRows(rows.sort(comparer));
        }

        setSort([sortColumn, sortDirection])
    }

    return (<div style={{width: '100%'}}><DataGrid
        columns={columns}
        rows={rows}
        defaultColumnOptions={{
            sortable: true,
            resizable: true
        }}

        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={handleSort}

        className="fill-grid"
        style={{resize:'both', width:'100%'}}
    />
    <CSVLink data={csvData}>Download (csv)</CSVLink>
    </div>)
}

export default AnalysisOutputTable;