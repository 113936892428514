import React, { useState, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import DataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';

const Scratchpad = props => {
    const columns = [
  { key: 'id', name: 'ID' },
  { key: 'title', name: 'Title' }
];

const rows = [
  { id: 0, title: 'Example' },
  { id: 1, title: 'Demo' },
  {id: 2, title: 'Foo'}
];

    return (<DataGrid
      columns={columns}
      rows={rows}
    />);
}

export default Scratchpad;
