export function friendlyFormat(num) {
    // v.simScore.toLocaleString(undefined, {maximumFractionDigits: 3})
    if (num < 1000) {
        return num;
    } else if (num < 1000000) {
        return (.001 * num).toLocaleString(undefined, {maximumFractionDigits: 1}) + 'k'
    } else if (num < 1000000000) {
        return (.000001 * num).toLocaleString(undefined, {maximumFractionDigits: 1}) + 'M'
    } else {
        return (.000000001 * num).toLocaleString(undefined, {maximumFractionDigits: 1}) + 'B'
    }
}