import {combineReducers } from 'redux';
import ajaxReducer from './ajaxReducer';
import userReducer from "./userReducer";
import tenantReducer from "./tenantReducer";
import taskReducer from "./taskReducer";

const rootReducer = combineReducers({
    ajax: ajaxReducer,
    user: userReducer,
    tenant: tenantReducer,
    task: taskReducer
});

export default rootReducer;