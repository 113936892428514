import React from 'react';
import { connect } from "react-redux";
import {ajaxWatched, apiRequest, parseLinks} from "../../shared/services";
import DataGrid, { SelectColumn } from 'react-data-grid';
import { Button, ButtonGroup, Container, Row, Col, Spinner } from "react-bootstrap";

// https://github.com/adazzle/react-data-grid/blob/canary/stories/demos/AllFeatures.tsx

function callApi(resource, callback){
    let headers = undefined;
    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, resource)
                .then(response => {headers = response.headers; return response.json()})
                .then(json => callback({
                    totalCount: parseInt(headers.get('Total-Count')),
                    links: parseLinks(headers),
                    items: json.result.items
                }))
        ))
    }
}

class PagedResultsDataGrid extends React.Component {
    constructor(props) {
        super(props);

        const foo = ['Term', 'Count'].map(c => ({key:c.toLowerCase(), name:c}));
        foo.unshift(SelectColumn);

        this.state = {
            totalCount: 0,
            links: {},
            items: [],
            sort: 'asctime-1',
            sortColumn: null,
            sortDirection: 'NONE',
            selectedRows: new Set()
        };

        this.columns = [SelectColumn];
    }

    refresh(resource){
        this.props.dispatch(callApi(resource, r => {
            this.setState({selectedRows: new Set(), ...r});
        }));
    }

    componentDidMount() {
        this.refresh(this.props.resource);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.props.resource !== nextProps.resource){
            this.refresh(nextProps.resource);
        }
        return true;
    }

    handleOnSort(sortColumn, sortDirection) {
        /*const comparer = (a, b) => {
            if (sortDirection === 'ASC'){
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } else if (sortDirection === 'DESC') {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
        };*/

        let newState = {
            sortColumn: sortColumn,
            sortDirection: sortDirection
        };

        this.setState(newState, () => {
            let sortSpec = undefined;
            if (this.props.onSort !== undefined) {
                switch (sortDirection) {
                    case 'DESC':
                        sortSpec = sortColumn + '-1';
                        break;
                    case 'ASC':
                        sortSpec = sortColumn + '+1';
                        break;
                    default:
                        sortSpec = undefined;
                        break;
                }
                this.props.onSort(sortSpec);
            }
        });
    }

    setSelectedRows = (r) => {
        if (r.size > 0)
        {
            r = new Set([Array.from(r).pop()]);
        }

        this.setState({
            selectedRows: r
        });
    }

    render()
    {
        if (this.state.items.length === 0){
            return <Spinner animation="border" />;
        }
        let detail = (<div>(select a row to see the details)</div>);
        let columnKeys = this.columns.map(c => c.key);
        let itemCols = Object.keys(this.state.items[0]);

        for(var i = 0; i < itemCols.length; i++){
            if (columnKeys.indexOf(this.state.items[i]) < 0) {
                let c = itemCols[i];
                this.columns.push({
                    key: c,
                    name: c,
                    sortable: true,
                    resizable: true
                });
            }
        }

        let links = Object.entries(this.state.links).map(i => <Button key={i[0]} value={i[1]} onClick={(e) => this.refresh(e.target.value)}>{i[0]}</Button>);
        if (links.length > 0) {
            links = <ButtonGroup>{links}</ButtonGroup>
        } else {
            links = ''
        }

        let grid = (
            <div className="my-2">
                <DataGrid
                    columns={this.columns}
                    rows={this.state.items}
                    defaultColumnOptions={{sortable: true, resizable: true}}

                    sortColumn={this.state.sortColumn}
                    sortDirection={this.state.sortDirection}
                    onSort={(a, b) => this.handleOnSort(a, b)}

                    rowKeyGetter={r => this.state.items.indexOf(r)}
                    selectedRows={this.state.selectedRows}
                    onSelectedRowsChange={this.setSelectedRows}

                    className="fill-grid"
                    style={{resize:'both', width:'100%'}}
                />
                {links} <span>{this.state.totalCount} items</span>
            </div>
        )

        if (this.state.selectedRows.size > 0){
            let row = this.state.items[Array.from(this.state.selectedRows)[0]];
            detail = Object.keys(row).map((k) => (<div key={k}><h5>{k}</h5><pre>{row[k]}</pre></div>));
            detail = <div><h4>Details:</h4>{detail}</div>
        }

        return (<Container>
            <Row>
                <Col>
                    {grid}
                </Col>
            </Row>
            <Row>
                <Col>
                    {detail}
                </Col>
            </Row>
        </Container>);
    }
}

function mapStateToProps(state){
    return {
    }
}

export default connect(mapStateToProps)(PagedResultsDataGrid);