import React from 'react';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const colors = ['#0B3954', '#15A480', '#9748A0', '#FFBC42', '#F06543'];

const AnalysisOutputChart = props => {
    let results = props.results;
    let renderAs = props.renderAs || "line";

    const explodeRow = row => {
        return row.reduce((map, cellVal, colIdx) => {
            map[results.columns[colIdx]] = cellVal;
            return map;
        }, {})
    }

    let data = results.rows.reduce((arr, row) => {
        arr.push(explodeRow(row));
        return arr
    }, []);

    if (renderAs === "bar") {
        let series = props.dataSeries.map((c, i) => <Bar type="monotone" dataKey={c} fill={colors[i % colors.length]} />)

        return (
            <ResponsiveContainer width='100%' aspect={4.0 / 2.0}>
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5, right: 30, left: 20, bottom: 5,
                    }}>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey={props.xAxisKey}/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    {series}
                </BarChart>
            </ResponsiveContainer>
        )
    }

    if (renderAs === "vbar") {
        let series = props.dataSeries.map((c, i) => <Bar type="monotone" dataKey={c} fill={colors[i % colors.length]} />)

        return (
            <ResponsiveContainer width='100%' aspect={4.0 / 2.0}>
                <BarChart
                    layout="vertical"
                    data={data}
                    margin={{
                        top: 5, right: 30, left: 20, bottom: 5,
                    }}>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <YAxis dataKey={props.xAxisKey} type="category" width={150}/>
                    <XAxis type="number"/>
                    <Tooltip/>
                    <Legend />
                    {series}
                </BarChart>
            </ResponsiveContainer>
        )
    }

    // line is the default
    let series = props.dataSeries.map((c, i) => <Line type="monotone" dataKey={c} stroke={colors[colors.length % i]}
                                                      activeDot={{r: 8}}/>);

    return (
        <ResponsiveContainer width='100%' aspect={4.0 / 2.0}>
            <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                }}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey={props.xAxisKey}/>
                <YAxis/>
                <Tooltip/>
                <Legend/>
                {series}
            </LineChart>
        </ResponsiveContainer>
    )
}

export default AnalysisOutputChart;