import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { apiRequest, ajaxWatched } from "../../shared/services";
import { userAuthenticated} from "../../shared/actions/user";
import { selectTenant} from "../../shared/actions/tenant";


function authenticateUser(userName, password, tenantId) {
    let resource = '/auth-tokens/' + encodeURIComponent(userName);

    return (dispatch, getState) => {
        dispatch(ajaxWatched(
            apiRequest(dispatch, getState, resource,
                {headers: {Authorization: 'Basic ' + btoa(userName + ':' + password)}},
                tenantId)
                .then(response => response.json())
                .then(json => {
                    dispatch(userAuthenticated(json.result, userName, password, tenantId));
                    dispatch(selectTenant(json.result.capabilities.tenant));})
        ));
    }
}

const LogIn = props => {
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [tenant, setTenant] = useState(props.match.params.tenantId);

    if (props.tenant) {
        let home ='/helm/t/' + encodeURIComponent(props.tenant.id);
        return <Redirect to={home} />
    }

    function logIn(event) {
        event.preventDefault();
        props.dispatch(authenticateUser(username, password, tenant))
    }

    return (
        <div className='login-container'>
            <form className='login-form' onSubmit={logIn}>
                <h2>Log In</h2>
                <label>Username:
                    <input type="text" name="username" defaultValue={username} onChange={(e) => setUsername(e.target.value)} />
                </label>
                <label>Password:
                    <input type="password" name="password" defaultValue={password} onChange={(e) => setPassword(e.target.value)} />
                </label>
                <label>Tenant ID:
                    <input type="text" name="tenant" defaultValue={tenant} onChange={(e) => setTenant(e.target.value)} />
                </label>

                <input type="submit" value="Log In" />
            </form>
        </div>
    );
}

function mapStateToProps(state){
    return {tenant: state.tenant.selectedTenant};
}

export default connect(mapStateToProps)(LogIn);