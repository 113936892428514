import React from 'react';
import { connect } from 'react-redux';
import Header from './Header';
import Nav from './Nav';
import './layout.css';
import Footer from "./Footer";


const Layout = props => {
    let {children} = props;
    return (
        <div className='layout-container'>
            <Nav />
            <div className='layout-content-container'>
                <Header />


                {children}

                <Footer />
            </div>
        </div>
    )
}

function mapStateToProps(state){
    return {
        'requests': state.ajax.requestCount
    }
}

export default connect(mapStateToProps)(Layout);