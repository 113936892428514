import React from 'react';
import { connect } from "react-redux";
import ContentScroller from "../../shared/components/ContentScroller";
import PagedResultsDataGrid from "../../shared/components/PagedResultsDataGrid";
import { getPagedRequest } from "../../shared/services";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import { friendlyFormat } from "../../shared/utils";
import { Container, Spinner } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


function buildLogSearchResource(levelNames, sortBy, callback) {
    let queryString = [];
    if (levelNames != null && levelNames.length > 0){
        levelNames = levelNames.join(',');
        levelNames = 'levels=' + encodeURIComponent(levelNames);
        queryString.push(levelNames);
    }
    else
    {
        levelNames = '';
    }

    if (sortBy == null){
        sortBy = 'asctime-1';
    }
    sortBy = 'sort=' + encodeURIComponent(sortBy);
    queryString.push(sortBy);
    queryString.push('take=200');

    if (queryString.length > 0){
        queryString = '?' + queryString.join('&');
    }
    else
    {
        queryString = '';
    }

    let resource = '/logs' + queryString;
    return resource;
}

class LogDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            levelFilter: [],
            levelStats: [],
            sortBy: undefined,
            currentResource: buildLogSearchResource([], undefined)
        }
    }

    componentDidMount() {
        this.props.dispatch(getPagedRequest(
            '/logs?do=log_counts_by_level&take=100',
            ({items}) => {
                this.setState({
                    levelStats: items
                })
            }
        ))
    }

    handleOnSort = (sortSpec) => {
        let resource = buildLogSearchResource(this.state.levelFilter, sortSpec);
        this.setState({
            sortBy: sortSpec,
            currentResource: resource
        });
    }

    handleLevelFilter = (levels) => {
        let resource = buildLogSearchResource(levels, this.state.sortBy);
        this.setState({
            levelFilter: levels,
            currentResource: resource
        });
    }

    render(){
        let levelSelector = <Spinner animation="border" />;
        if (this.state.levelStats.length > 0){
            let btns = this.state.levelStats.map(s => (<ToggleButton
                key={s.groupKey.levelname}
                value={s.groupKey.levelname}
                title={s.metrics.levelname_count}
                className="mx-2"
                ><h3>{friendlyFormat(s.metrics.levelname_count)}</h3><p>{s.groupKey.levelname}</p></ToggleButton>));
            levelSelector = <ToggleButtonGroup type="checkbox" onChange={this.handleLevelFilter} value={this.state.levelFilter}>{btns}</ToggleButtonGroup>
        }

        return <ContentScroller>
            <Container>
                <Row>
                    <Col>
                        <h2>Application Logs</h2>
                        {levelSelector}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PagedResultsDataGrid
                            resource={this.state.currentResource}
                            onSort={this.handleOnSort}
                        />
                    </Col>
                </Row>
            </Container>
        </ContentScroller>
    }
}

function mapStateToProps(state){
    return {
    }
}

export default connect(mapStateToProps)(LogDetails)